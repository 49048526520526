import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { api, axios, types, pluralMap } from '../config';
import ListFilters from './ListFilters';
import ListItem from './ListItem';
import ListItemWithToggle from './ListItemWithToggle';
import Legend from './Legend';
import Store from '../store';
import sync from '../sync';
import useStructureStats from '../hooks/useStructureStats';
import { useListFilter } from '../contexts/ListFilterProvider';

function getNewButton(type, goToForm, showAllSpaces) {
  if (type === types.college || (type === types.space && !showAllSpaces)) {
    return null;
  }

  return (
    <div
      className={`btn btn--secondary create ${type}`}
      id={`new-${type}-btn`}
      onClick={() => goToForm()}
    >
      New {type}
    </div>
  );
}

function getSpacesToggle(showAllSpaces, setShowAllSpaces, setIsOnline) {
  async function toggleShowAllSpaces() {
    await sync
      .checkNetwork()
      .then(() => {
        sync.isOnline = true;
        setIsOnline(true);
      })
      .catch(() => {
        setIsOnline(false);
      })
      .finally(() => {
        setShowAllSpaces((prevState) => !prevState);
      });
  }

  return (
    <div className="toggle-container">
      <label htmlFor="showAllToggle">Show all spaces</label>
      <input
        className="toggle-switch toggle-switch-on-off"
        type="checkbox"
        id="showAllToggle"
        checked={showAllSpaces}
        onChange={toggleShowAllSpaces}
      />
    </div>
  );
}

function getInspectionToggle(setBaseItems, inspectionId) {
  async function toggleInInspection(item) {
    const method = item.attributes['inspection_ids'].includes(
      parseInt(inspectionId)
    )
      ? 'DELETE'
      : 'PUT';

    axios({
      method: method,
      url: `${api.inspections}/${inspectionId}/spaces/${item.id}`,
      data: item,
    }).then((res) => {
      const { data } = res;
      const store = Store.instance(pluralMap['space']);
      const currentLevelId = data.data.relationships.level.data.id;

      store.upsert(data.data);
      store.get().then((spaces) => {
        setBaseItems(() => {
          return spaces.filter((space) => {
            return space.relationships.level.data.id === currentLevelId;
          });
        });
      });
    });
  }
  return toggleInInspection;
}

/**
 * @params {string} type
 * @params {array} items
 * @params {selectionHandler} function
 */
export default function List({
  type,
  categoryCounter,
  selectionHandler,
  goToForm,
  inspection,
  parentItem,
}) {
  const inspectionId = inspection && inspection.id;
  const className = `crud-component ${pluralMap[types[type]]}`;
  const [showAllSpaces, setShowAllSpaces] = useState(false);
  const [isOnline, setIsOnline] = useState(sync.isOnline);

  const inSelectedInspection = (item) => {
    return (
      item.attributes['inspection_ids'] &&
      item.attributes['inspection_ids'].includes(parseInt(inspectionId))
    );
  };

  const {
    isSortAsc,
    setSearchTerm,
    searchTerm,
    setIsSortAsc,
    filteredItems,
    showUnscoredOnly,
    setShowUnscoredOnly,
    showFilters,
    setShowFilters,
    setBaseItems,
  } = useListFilter();

  const { statsData } = useStructureStats({
    structureType: type,
    inspectionId,
    parentItem,
  });

  const getListItems = () => {
    if (type !== types.space) {
      return filteredItems.map((item, key) => {
        return ListItem({
          item,
          key,
          selectionHandler,
          categoryCounter,
          statsData,
        });
      });
    } else if (showAllSpaces) {
      return filteredItems.map((item, key) => {
        return ListItemWithToggle({
          item,
          key,
          selectionHandler,
          categoryCounter,
          isOnline,
          inSelectedInspection: inSelectedInspection(item),
          toggleInInspection: getInspectionToggle(setBaseItems, inspectionId),
        });
      });
    } else {
      return filteredItems.reduce((itemsArr, item, key) => {
        if (inSelectedInspection(item)) {
          itemsArr.push(
            ListItem({
              item,
              key,
              selectionHandler,
              categoryCounter,
              statsData,
            })
          );
        }
        return itemsArr;
      }, []);
    }
  };

  const listItems = getListItems();

  return (
    <div className={className}>
      {statsData && statsData.property && inspection ? (
        <div className="structure-stats">
          <div className="total-stats-container">
            <div>Job Number:</div>
            <div className="font-bold">{inspection.attributes.job_number}</div>
          </div>
          <div className="total-stats-container">
            <div>Selected Spaces Scored:</div>
            <div className="font-bold">{`${statsData.property.space.scored}/${statsData.property.space.total}`}</div>
          </div>
          <div className="total-stats-container">
            <div>Units Scored:</div>
            <div className="font-bold">{`${statsData.property.unit.scored}/${statsData.property.unit.total} (${statsData.property.unit.pct})`}</div>
          </div>
        </div>
      ) : null}
      <div className="list-topper">
        <ListFilters
          categoryCounter={categoryCounter}
          isSortAsc={isSortAsc}
          setIsSortAsc={setIsSortAsc}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showUnscoredOnly={showUnscoredOnly}
          setShowUnscoredOnly={setShowUnscoredOnly}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        {type === types.space &&
          getSpacesToggle(showAllSpaces, setShowAllSpaces, setIsOnline)}
        {categoryCounter ? <Legend /> : null}
      </div>
      <div>
        <h2 className="capitalize">
          Select {type}
          <span className="sub-title" style={{ paddingLeft: '5px' }}>
            —&nbsp;
            {listItems.length}{' '}
            {listItems.length === 1 ? type : pluralMap[types[type]]}
          </span>
          {[types.building, types.level].includes(type) && (
            <div className="stats-legend">
              <div className="legend-container center-align">
                <div className="red-square"></div>
              </div>
              <div style={{ paddingRight: '8px' }}>Selected Spaces Scored</div>

              <div className="legend-container center-align">
                <div className="dark-grey-square"></div>
              </div>
              <div>Units Scored</div>
            </div>
          )}
        </h2>
      </div>
      <div className="crud-component__list-container">
        {listItems.length > 0 ? (
          <ul className="list-component">{listItems}</ul>
        ) : (
          `No ${pluralMap[types[type]]} to show.`
        )}
      </div>
      {getNewButton(type, goToForm, showAllSpaces)}
    </div>
  );
}

List.propTypes = {
  categoryCounter: PropTypes.object,
  goToForm: PropTypes.func.isRequired,
  inspection: PropTypes.object,
  items: PropTypes.array.isRequired,
  selectionHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  parentItem: PropTypes.object,
};

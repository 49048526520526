import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function buildOptions(options) {
  return options.map((option, index) => {
    const { name, value } = option;
    return (
      <option key={index} value={value}>
        {name}
      </option>
    );
  });
}

function getSelect(name, state, options, update, setRequired) {
  return (
    <Fragment>
      {setRequired && <span className="rflag">*</span>}
      <select
        onChange={update}
        value={state || 'noop'}
        name={name}
        id={name}
        className="form-group__field"
        required={setRequired}
      >
        <option disabled value="noop">
          {' '}
          -- select an option --{' '}
        </option>
        {buildOptions(options)}
      </select>
    </Fragment>
  );
}

export default function FormSelectGroup({
  label,
  name,
  options,
  state,
  update,
  setRequired,
}) {
  return (
    <div className="form-group">
      <label className="required">
        {label}
        {getSelect(name, state, options, update, setRequired)}
      </label>
    </div>
  );
}

FormSelectGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  setRequired: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { types } from '../config';

function getCounter(counter) {
  const { maintenance, custodial } = counter;
  let maintenanceCounter = null;
  let custodialCounter = null;

  if (custodial.total) {
    custodialCounter = (
      <div className="category-counter custodial-counter">
        {custodial.scored}/{custodial.total}
      </div>
    );
  }
  if (maintenance.total) {
    maintenanceCounter = (
      <div className="category-counter maintenance-counter">
        {maintenance.scored}/{maintenance.total}
      </div>
    );
  }
  return (
    <div className="category-counter-wrap">
      {custodialCounter}
      {maintenanceCounter}
    </div>
  );
}

function getSpaceDescription(space) {
  const sameAsName = space.attributes.description == space.attributes.name;
  return (
    <span>
      {!sameAsName && (
        <p className="list-component__sub">{space.attributes.description}</p>
      )}
      <p className="list-component__sub">{space.attributes.space_type.name}</p>
    </span>
  );
}

/**
 * @params {string} type
 */
export default function ListItem({
  item,
  key,
  selectionHandler,
  categoryCounter,
  noCaret,
  statsData,
}) {
  const clickHandler = (e) => selectionHandler(item);
  const styles = noCaret ? { backgroundImage: 'none' } : {};
  let counter = null;
  if (categoryCounter) {
    counter = getCounter(categoryCounter[item.id]);
  }
  const data =
    statsData &&
    statsData.property &&
    [types.level, types.building].includes(item.type) &&
    statsData[item.type] &&
    statsData[item.type][item.id];

  return (
    <li
      onClick={clickHandler}
      key={key}
      className="list-component__item"
      style={styles}
    >
      <div className="list-component__item_container">
        <div className="list-component__name">{item.attributes.name}</div>
        <div>{item.type === types.space && getSpaceDescription(item)}</div>
      </div>
      {data && (
        <div className="list-component__stats">
          <div className="list-component_stat_space">
            {`${data.space.scored}/${data.space.total}`}
          </div>
          <div className="list-component_stat_unit">
            {`${data.unit.scored}/${data.unit.total} (${data.unit.pct})`}
          </div>
        </div>
      )}
      {counter}
    </li>
  );
}

ListItem.propTypes = {
  categoryCounter: PropTypes.object,
  item: PropTypes.object.isRequired,
  selectionHandler: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
  noCaret: PropTypes.bool,
  result: PropTypes.object,
};

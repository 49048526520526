import { useDrag, useDrop } from 'react-dnd';
import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

export default function ReportDraggable({
  index,
  removePhotoComment,
  moveItem,
  content,
  classes,
}) {
  const confirmDelete = (e) => {
    e.preventDefault();
    if (confirm('Are you sure you want to delete this item?')) {
      removePhotoComment();
    }
  };

  // https://github.com/react-dnd/react-dnd/issues/1558
  useLayoutEffect(() => {
    window.__isReactDndBackendSetUp = false;
  }, []);

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'photo-item',
    drop(item, monitor) {
      moveItem(item.index, index, true);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'photo-item', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  let className = 'custodial-photo-item report-list-item';
  if (classes) {
    className += ` ${classes}`;
  }

  return (
    <div ref={ref} style={{ ...style, opacity }} className={className}>
      <a
        href="#"
        onClick={confirmDelete}
        className="custodial-photo-remove fa fa-remove"
      ></a>
      {content}
    </div>
  );
}

ReportDraggable.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  removePhotoComment: PropTypes.func.isRequired,
  classes: PropTypes.string,
  index: PropTypes.number.isRequired,
  moveItem: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import sync from '../sync';

function handleNotInInspection(space) {
  alert(
    `Cannot score space. The space "${space.attributes.name}" is not assigned to the current inspection.`
  );
}

function getToggle(item, handleToggleClick, inSelectedInspection) {
  return (
    <div className="toggle-container">
      <input
        className="toggle-switch"
        type="checkbox"
        checked={inSelectedInspection}
        onChange={handleToggleClick}
      />
    </div>
  );
}

export default function ListItemWithToggle({
  item,
  key,
  categoryCounter,
  selectionHandler,
  toggleInInspection,
  isOnline,
  inSelectedInspection,
}) {
  const noCaret = !inSelectedInspection;
  selectionHandler = inSelectedInspection
    ? selectionHandler
    : handleNotInInspection;
  categoryCounter = inSelectedInspection ? categoryCounter : null;

  function handleToggleClick(e) {
    e.preventDefault();
    sync
      .checkNetwork()
      .then(() => {
        sync.isOnline = true;
        toggleInInspection(item);
      })
      .catch(() => {
        alert('Cannot move spaces in/out of inspections while offline.');
      });
  }

  return (
    <div key={key} className="flex">
      {isOnline && getToggle(item, handleToggleClick, inSelectedInspection)}
      {ListItem({ item, key, categoryCounter, selectionHandler, noCaret })}
    </div>
  );
}

ListItemWithToggle.PropTypes = {
  categoryCounter: PropTypes.object,
  item: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  toggleInInspection: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
  inSelectedInspection: PropTypes.bool.isRequired,
};

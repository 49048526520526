import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Tooltip({ content, text }) {
  const [show, setShow] = useState(false);
  let className = 'tooltip-box';

  if (show) {
    className += ' visible';
  }

  return (
    <div
      className='tooltip-container'
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className={className}>
        {text}
        <span className="tooltip-arrow" />
      </div>
      {content}
    </div>
  );
}

Tooltip.propTypes = {
  content: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SpaceInfo from './SpaceInfo';
import ScoreIssues from './ScoreIssues';
import ScoreButtons from './ScoreButtons';
import { isIOS, scoreValuePercentMap } from '../config';

function buildImageTags(score) {
  if (!score || !score.attributes) {
    return null;
  }
  const urls = score.attributes.image_urls;
  return Object.keys(urls).map((id) => {
    return (
      <li key={id} className="photo">
        <img className="photo" src={urls[id].path} />
      </li>
    );
  });
}

function buildNotes(selectedIssue, updateNotes, updateIssue) {
  const notes =
    selectedIssue.notes ||
    (selectedIssue.score ? selectedIssue.score.attributes.notes : '');
  return (
    <div className="form-group">
      <label htmlFor="score-notes">Notes</label>
      <textarea
        onBlur={(e) => updateIssue({ notes: e.target.value })}
        className="form-group__field"
        id="score-notes"
        name="notes"
        onChange={(e) => updateNotes(e)}
        value={notes}
      />
    </div>
  );
}

function buildPhotoGrid(openImage, choosePhoto, score, uploadPhoto) {
  return (
    <div className="form-group">
      <label htmlFor="score-photos">Photos</label>
      <div className="score-photos-component">
        <div
          onClick={choosePhoto}
          className="btn btn--secondary add-photo"
          id="score-photos"
        >
          Add Photo
        </div>
        <input
          ref={openImage}
          onChange={(e) => uploadPhoto(e)}
          accept="image/*"
          id="photo-trigger"
          type="file"
          capture="camera"
          multiple={!isIOS}
        />
        <div className="photos-grid"></div>
        <div className="previous-photos">
          <ul className="photos-names">{buildImageTags(score)}</ul>
        </div>
      </div>
    </div>
  );
}

export default function ScorePanel(props) {
  const {
    space,
    items: issues,
    clearAllScores,
    currentIssueIndex,
    updateNotes,
    updateIssue,
    uploadPhoto,
    scoreAllIssues,
    setCurrentIssue,
  } = props;
  const selectedIssue = issues[currentIssueIndex];

  if (!selectedIssue) {
    console.error('No issue was selected!');
    return;
  }

  const currentIssueId = selectedIssue.spaceTypeIssue.attributes.issue.id;
  const currentIssueCode = selectedIssue.spaceTypeIssue.attributes.issue.code;
  const [showScoreAll, setScoreAll] = useState(false);
  const openImage = useRef(null);

  function choosePhoto() {
    openImage.current.click();
  }

  function toggleScoreAll() {
    setScoreAll(!showScoreAll);
  }

  function buttonValues() {
    if (currentIssueCode == 'reactive_v_preventative') {
      return [1, 2, 3, 4, 5, 0].map((v) => scoreValuePercentMap[v]);
    }

    return [1, 2, 3, 4, 5, 0];
  }

  return (
    <div className="inspection-container">
      <SpaceInfo
        issues={issues}
        showScoreAll={showScoreAll}
        clearAllScores={clearAllScores}
        toggleScoreAll={toggleScoreAll}
        name={space.attributes.name}
        spaceType={space.attributes.space_type.name}
        description={space.attributes.description || ''}
      />
      <div className="panels-container">
        <ScoreIssues
          issues={issues}
          currentIssueId={currentIssueId}
          setCurrentIssue={setCurrentIssue}
        />
        <div className="score-panel">
          <form className="score" id="create-score-form">
            <ScoreButtons
              isScoreAll={showScoreAll}
              values={buttonValues()}
              currentIssueScore={selectedIssue.value}
              updateIssue={updateIssue}
              scoreAllIssues={scoreAllIssues}
            />

            {showScoreAll ||
              buildNotes(selectedIssue, updateNotes, updateIssue)}
            {showScoreAll ||
              buildPhotoGrid(
                openImage,
                choosePhoto,
                selectedIssue.score,
                uploadPhoto
              )}
          </form>
        </div>
      </div>
    </div>
  );
}

ScorePanel.propTypes = {
  clearAllScores: PropTypes.func.isRequired,
  currentIssueIndex: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setCurrentIssue: PropTypes.func.isRequired,
  scoreAllIssues: PropTypes.func.isRequired,
  space: PropTypes.object.isRequired,
  updateIssue: PropTypes.func.isRequired,
  updateNotes: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
};

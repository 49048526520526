import React from 'react';
import PropTypes from 'prop-types';

/**
 * @params {string} type
 */
export default function ReportListItem({
  inspection,
  collegeName,
  propertyName,
  city,
  status,
  key,
  selectInspection,
}) {
  const clickHandler = () => selectInspection(inspection.id);
  return (
    <li onClick={clickHandler} key={key} className="list-component__inspection">
      <div className="list-component__badge">{status || 'new'}</div>
      <p className="list-component__header">{collegeName}</p>
      <p className="list-component__name">{`${inspection.attributes.job_number} - ${propertyName}`}</p>
      <p className="list-component__sub">{city}</p>
    </li>
  );
}

ReportListItem.propTypes = {
  inspection: PropTypes.object.isRequired,
  collegeName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  selectInspection: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
};

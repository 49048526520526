import { useEffect, useState } from 'react';
import { api, axios, network, types } from '../../config';

export default function useStructureStats({
  structureType,
  inspectionId,
  parentItem,
}) {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const getData = () => {
      const url =
        structureType === types.building || structureType === types.space
          ? `${api.inspections}/${inspectionId}/stats`
          : structureType === types.level
          ? `${api.inspections}/${inspectionId}/buildings/${parentItem.id}/stats`
          : '';

      axios.get(url).then((response) => setResponse(response));
    };

    if (!network.simulateOffline && inspectionId) {
      getData();
    }
  }, [structureType, inspectionId, parentItem]);

  return {
    statsData:
      !response || response.error || response.data.error ? null : response.data,
  };
}

import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';

export default class FacilityDashboard extends ReportSection {
  render() {
    return (
      <Fragment>{this.textarea('maintenance_introduction_text')}</Fragment>
    );
  }
}

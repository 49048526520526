export const filterScoredItems = ({
  showUnscoredOnly,
  items,
  categoryCounter,
}) => {
  if (!showUnscoredOnly || !categoryCounter) {
    return items;
  }

  return items.filter((item) => {
    const { maintenance, custodial } = categoryCounter[item.id];

    return (
      maintenance.scored !== maintenance.total ||
      custodial.scored !== custodial.total
    );
  });
};

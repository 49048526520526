import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';
import { humanize } from '../../utils';
import { reportFinalized } from '../../containers/Reports';
import PhotoGallery from '../../containers/PhotoGallery';
import Loading from '../Loading';
import { axios, api } from 'config';

const editItems = ['evaluation_type', 'college', 'property', 'city', 'state'];

export default class GeneralInfo extends ReportSection {
  componentDidMount() {
    if (reportFinalized(this.props.content.status)) {
      this.getPhotos();
    } else {
      this.setState({ ready: true });
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.setState({ ready: false });
      this.getPhotos();
    }
  }

  getPhotos() {
    const endpoint = `${api.inspections}/${this.props.currentInspectionId}/photos`;
    return axios.get(endpoint).then((res) => {
      const { data } = res;
      this.setState({
        photos: data,
        ready: true,
      });
    });
  }

  setStatus(value) {
    this.setState(
      {
        status: value,
      },
      () => {
        this.saveSection('status');
      }
    );
  }

  async confirmStatus(status, validateReport) {
    const reportValidation = await validateReport();

    if (!reportValidation.isValid) {
      alert(reportValidation.messages.join('\r\n\n'));
      if (reportValidation.data) {
        this.setState({
          validationData: reportValidation.data,
        });
      }
    } else {
      if (
        confirm(
          "Once status is set to 'Final' the report can no longer be modified. Please review all data before setting report status to 'Final'. Are you sure you want to continue?"
        )
      ) {
        this.setStatus(status);
        this.archiveReport();
      }
    }
  }

  render() {
    const { content, validateReport, currentInspectionId } = this.props;
    if (!this.state.ready) {
      return <Loading />;
    }
    return (
      <Fragment>
        {editItems.map((name) => this.listItem(name))}
        <div className="report-list-item" id="report-status">
          <div className="report-item-label capitalize">Status</div>
          <select
            className="report-item-select"
            name="status"
            onChange={(e) => this.confirmStatus(e.target.value, validateReport)}
            value={content.status}
            disabled={reportFinalized(content.status)}
          >
            {content.status === 'revising' ? (
              <Fragment>
                <option value="revising">Revising</option>
                <option value="revised final">Revised Final</option>
              </Fragment>
            ) : (
              <Fragment>
                <option value="draft">Draft</option>
                <option value="final">Final</option>
              </Fragment>
            )}
            {reportFinalized(content.status) && (
              <option value="revised final">Revised Final</option>
            )}
          </select>
        </div>
        {reportFinalized(content.status) && (
          <div className="center">
            <h3>
              This report has been finalized and can no longer be modified.
            </h3>
            <div style={{margin: "25px 0"}}>
              <PhotoGallery
                photos={this.state.photos}
                selectImage={false}
                buttonText="Photo Gallery"
              />
            </div>
            <a
              className="btn btn--primary"
              href={'/inspections/' + currentInspectionId + '/reports.pdf'}
              target="_blank"
            >
              View PDF
            </a>
          </div>
        )}

        {this.state.validationData && (
          <div>
            <h3>
              The following sections have fields that still need to be
              confirmed:
            </h3>
            <ul>
              {Object.keys(this.state.validationData).map((section_name) => {
                return (
                  <li key={section_name}>
                    {section_name}
                    <ul>
                      {this.state.validationData[section_name].map((field) => {
                        if (field != true) {
                          return <li key={field}> {humanize(field)} </li>;
                        }
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Fragment>
    );
  }
}

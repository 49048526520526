import React from 'react';
import PropTypes from 'prop-types';
import { scoreValuePercentMap } from '../config';

export default function ScoreIssues({
  issues,
  currentIssueId,
  setCurrentIssue,
}) {
  function issueCategory(issue) {
    return issue.spaceTypeIssue.attributes.issue_category;
  }

  function scoreItem(issue, key, currentIssueId, setCurrentIssue) {
    let liClassName = 'list-component__issue';
    let btnClassName = 'btn btn--round btn--secondary issue-score';
    const issueId = issue.spaceTypeIssue.attributes.issue.id;
    const issueCode = issue.spaceTypeIssue.attributes.issue.code;
    if (issueId === currentIssueId) {
      liClassName += ' selected';
    }
    if (issueCategory(issue) === 'maintenance') {
      liClassName += ' maintenance-item';
    }
    if (issueCategory(issue) === 'custodial') {
      liClassName += ' custodial-item';
    }

    let value = issue.value;
    if (value && issueCode === 'reactive_v_preventative') {
      value = scoreValuePercentMap[value];
      btnClassName += ' more-text';
    } else if (Number.isInteger(value)) {
      value = value > 0 ? value : 'n/a';
    } else {
      value = '-';
    }

    return (
      <li
        key={key}
        className={liClassName}
        onClick={() => setCurrentIssue(issue)}
      >
        <div className={btnClassName}>{value}</div>
        <div className={'list-component__name '}>
          {issue.spaceTypeIssue.attributes.issue.name}
        </div>
      </li>
    );
  }

  return (
    <div className="inspection-component__list-container">
      <ul className="list-component">
        {issues.map((issue, index) =>
          scoreItem(issue, index, currentIssueId, setCurrentIssue)
        )}
      </ul>
    </div>
  );
}

ScoreIssues.propTypes = {
  issues: PropTypes.array.isRequired,
  currentIssueId: PropTypes.number.isRequired,
  setCurrentIssue: PropTypes.func.isRequired,
};

import { types } from '../../config';

export const searchItems = ({ searchTerm, items }) => {
  if (searchTerm == '') {
    return items;
  }

  const regex = new RegExp(searchTerm, 'ig');

  return items.filter((item) => {
    const itemName = item.attributes.name;

    const filterKey =
      item.type === types.space
        ? itemName +
          [item.attributes.description, item.attributes.space_type.name].join(
            ' '
          )
        : itemName;

    return !!filterKey.match(regex);
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { scorePercentValueMap } from '../config';

const parseValue = (percentOrValue) => {
  if (!Number.isInteger(percentOrValue)) {
    return scorePercentValueMap[percentOrValue];
  } else {
    return percentOrValue;
  }
};

export default function ScoreButtons({
  values,
  isScoreAll,
  scoreAllIssues,
  currentIssueScore,
  updateIssue,
}) {
  const buttons = values.map((value, index) => {
    let className = 'btn btn--secondary btn--round';
    if (!isScoreAll && parseValue(value) === currentIssueScore) {
      className += ' selected';
    }
    if (!Number.isInteger(value)) {
      className += ' more-text';
    }

    const clickHandler = () => {
      if (isScoreAll) {
        scoreAllIssues({ value: parseValue(value) });
      } else if (parseValue(value) !== currentIssueScore) {
        updateIssue({ value: parseValue(value) });
      }
    };
    return (
      <div key={index} className={className} onClick={clickHandler}>
        {value || 'n/a'}
      </div>
    );
  });

  return (
    <div className="form-group">
      <label className="required">
        Score {isScoreAll && 'All'}
        <span className="rflag">*</span>
      </label>
      <div className="score-btns">{buttons}</div>
    </div>
  );
}

ScoreButtons.propTypes = {
  currentIssueScore: PropTypes.number,
  isScoreAll: PropTypes.bool.isRequired,
  scoreAllIssues: PropTypes.func.isRequired,
  updateIssue: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import ReportPhoto from './ReportPhoto';
import ReportDraggable from './ReportDraggable';
import ConfirmItem from './ConfirmItem';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

export default function ReportItemInfo({
  children,
  photos,
  previewPhoto,
  description,
  updateDescription,
  removePhotoComment,
  saveState,
  updatePhotoComment,
  updatePhotoCommentImage,
  comments,
  moveItem,
  confirmPhotoComment,
  confirmDescription,
  descriptionConfirmData,
}) {
  const descriptionNeedsConfirm =
    descriptionConfirmData.carryover_content != undefined &&
    !descriptionConfirmData.carryover_content;
  const findNote = (thumbnailPath) => {
    const photo = photos.find(
      ({ thumbnail_path }) => thumbnail_path === thumbnailPath
    );
    return photo ? photo.meta.score_notes : '';
  };

  const renderComments = (attrs, index) => {
    return (
      <ReportDraggable
        key={attrs.id} /* This id is necessary for textbox to focus */
        index={index}
        moveItem={moveItem}
        removePhotoComment={() => removePhotoComment(index)}
        classes={!!attrs.page_break ? 'page-break-item' : ''}
        content={
          !!attrs.page_break ? (
            'Page Break'
          ) : (
            <ReportPhoto
              saveState={() => saveState()}
              author={attrs.author}
              comment={attrs.comment}
              thumbnailPath={attrs.thumbnail_path}
              note={findNote(attrs.thumbnail_path)}
              previewPhoto={() => previewPhoto(attrs.image_path)}
              updatePhotoComment={(elem, needsConfirm) =>
                updatePhotoComment(elem, index, needsConfirm, attrs.confirmed)
              }
              updatePhotoCommentImage={(image) =>
                updatePhotoCommentImage(image, index)
              }
              photos={photos}
              needsConfirm={
                attrs.confirmed.carryover_content != undefined &&
                !attrs.confirmed.carryover_content
              }
              confirmItem={
                <ConfirmItem
                  confirmed={attrs.confirmed}
                  confirmHandler={() => confirmPhotoComment({ index })}
                  qcHandler={() => confirmPhotoComment({ index, qc: true })}
                />
              }
            />
          )
        }
      />
    );
  };

  let descriptionClasses = 'report-item-text grow';
  if (descriptionNeedsConfirm) {
    descriptionClasses += ' unconfirmed';
  }

  return (
    <div id="custodial-info">
      <div className="flex">
        <textarea
          id="custodial-info-description"
          className={descriptionClasses}
          value={description}
          placeholder="Add a description..."
          onChange={(e) =>
            updateDescription({
              el: e.target,
              needsConfirm: descriptionNeedsConfirm,
            })
          }
          onBlur={() => saveState()}
        />
        {description && (
          <ConfirmItem
            confirmed={descriptionConfirmData}
            confirmHandler={() => confirmDescription({ qc: false })}
            qcHandler={() => confirmDescription({ qc: true })}
          />
        )}
      </div>
      {children}
      <DndProvider backend={Backend} context={window}>
        {comments.map((attrs, index) => renderComments(attrs, index))}
      </DndProvider>
    </div>
  );
}

ReportItemInfo.propTypes = {
  moveItem: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
  previewPhoto: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveState: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired,
  updatePhotoComment: PropTypes.func.isRequired,
  removePhotoComment: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  confirmPhotoComment: PropTypes.func.isRequired,
  confirmDescription: PropTypes.func.isRequired,
  descriptionConfirmData: PropTypes.object.isRequired,
};

import SectionType from './SectionType';

export default class Custodial extends SectionType {
  constructor(props) {
    super(props);
    this.categoriesKey = 'custodial_categories';
  }

  get name() {
    return 'Custodial';
  }

  saveState() {
    this.saveSection('custodial_categories');
  }

  selectSpaceType(index) {
    this.setState({ spaceTypeIndex: index });
  }
}

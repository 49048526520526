import React from 'react';
import PropTypes from 'prop-types';
import ReportListItem from './ReportListItem';
import ReportCollegeListItem from './ReportCollegeListItem';
import ReportJobNumberListItem from './ReportJobNumberListItem';
import { useParams } from 'react-router-dom';

export default function ReportList({
  inspections,
  selectInspection,
  selectCollege,
  selectJobNumber,
}) {
  const { collegeName, jobNumber } = useParams();

  const getCollegeListItems = () => {
    const data = inspections.map(
      (inspection) => inspection.attributes.college_name
    );

    return [...new Set(data)].map((collegeName, key) =>
      ReportCollegeListItem({
        collegeName,
        selectCollege,
        key: key,
      })
    );
  };

  const getJobNumberListItems = () => {
    const data = inspections
      .filter(
        (inspection) => inspection.attributes.college_name === collegeName
      )
      .map((inspection) => inspection.attributes.job_number);

    return [...new Set(data)].map((jobNumber, key) =>
      ReportJobNumberListItem({
        jobNumber,
        selectJobNumber,
        key: key,
      })
    );
  };

  const getInspectionListItems = () => {
    return inspections
      .filter(
        (inspection) =>
          inspection.attributes.college_name === collegeName &&
          inspection.attributes.job_number === jobNumber
      )
      .map((inspection, key) => {
        const {
          college_name: collegeName,
          property_name: propertyName,
          city,
          status,
        } = inspection.attributes;

        return ReportListItem({
          inspection,
          collegeName,
          propertyName,
          city,
          status,
          selectInspection,
          key,
        });
      });
  };

  const getListItems = () => {
    if (!collegeName) {
      return getCollegeListItems();
    } else if (!jobNumber) {
      return getJobNumberListItems();
    } else {
      return getInspectionListItems();
    }
  };

  const getHeader = () => {
    if (!collegeName) {
      return 'College';
    } else if (!jobNumber) {
      return 'Job number';
    } else {
      return 'Report';
    }
  };

  return (
    <div className="crud-component" id="reports">
      <h2 className="capitalize">Select {getHeader()}</h2>
      <div className="crud-component__list-container">
        <ul className="list-component">{getListItems()}</ul>
      </div>
    </div>
  );
}

ReportList.propTypes = {
  selectInspection: PropTypes.func.isRequired,
  selectCollege: PropTypes.func.isRequired,
  selectJobNumber: PropTypes.func.isRequired,
  inspections: PropTypes.array.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { axios, api, pluralMap, types } from '../config';
import ScorePanel from '../components/ScorePanel';
import Store from '../store';
import Loading from '../components/Loading';

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
const imageSizeConstraint = 1200;

const defaultState = {
  selected: 0,
  updating: false,
};

// Cache processing of spaceTypeIssue by spaceTypeId
const spaceTypeIssuesCache = {};

export default class Space extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = Object.assign({}, defaultState);
  }

  setCurrentIssue(issue) {
    const newSelectedIndex = this.props.items.indexOf(issue);
    this.props.checkNetwork().then(() => {
      this.setState({ selected: newSelectedIndex });
    });
  }

  updateNotes(e) {
    const { selected } = this.state;
    const { items } = this.props;
    // don't mutate the state directly, use immutability-helper
    const updatedItems = update(items, {
      [selected]: {
        notes: {
          $set: e.target.value,
        },
      },
    });

    this.props.updateItems(updatedItems);
  }

  uploadPhoto(e) {
    const scope = this;
    const URL = window.URL || window.webkitURL;
    function resizeImage(e) {
      const { width, height } = this;
      const isLandscape = width > height;
      const maxDim = isLandscape ? 'width' : 'height';
      const resizeFactor = imageSizeConstraint / this[maxDim];
      // only need to resize if it is larger than sizeConstraint
      if (resizeFactor < 1) {
        canvas.width = width * resizeFactor;
        canvas.height = height * resizeFactor;
      } else {
        canvas.width = width;
        canvas.height = height;
      }
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/jpeg', 0.81);

      scope.updateIssue({ image });
      /*debuggging
      const win = window.open();
      win.document.write(`<img src="${image}"/>`);
      */
    }
    Array.from(e.target.files).forEach((file, i) => {
      const image = new Image();
      image.onload = resizeImage;
      image.src = URL.createObjectURL(file);
    });
  }

  getUnscoredItems(items) {
    return items.reduce((arr, item, index) => {
      if (!item.score || !Number.isInteger(item.score.attributes.value)) {
        arr.push(index);
      }
      return arr;
    }, []);
  }

  getScoredItems(items) {
    return items.reduce((arr, item, index) => {
      if (item.score && Number.isInteger(item.score.attributes.value)) {
        arr.push(index);
      }
      return arr;
    }, []);
  }

  scoreAllUnscored({ value }) {
    const { items } = this.props;
    const itemsToScore = this.getUnscoredItems(items);
    const size = itemsToScore.length;
    if (!size) {
      return;
    }

    this.setScoresForItems({ items, itemsToScore, value });
  }

  clearAllScores() {
    const { items } = this.props;
    const itemsToScore = this.getScoredItems(items);
    const size = itemsToScore.length;
    if (!size) {
      return;
    }

    this.setScoresForItems({ items, itemsToScore, value: null });
  }

  async setScoresForItems({ items, itemsToScore, value }) {
    this.setState({ updating: true });
    let itemList = items;
    for (let i = 0, size = itemsToScore.length; i < size; i++) {
      itemList = await this.updateIssue({
        value,
        itemList,
        explictNull: true,
        itemIndex: itemsToScore[i],
      });
    }
    this.props.updateItems(itemList);
    this.setState({ updating: false });
  }

  async updateIssue({ value, notes, image, itemList, itemIndex, explictNull }) {
    const isScoreAll = Number.isInteger(itemIndex);
    const { selected } = this.state;
    const { space, propertyId } = this.props;
    const items = isScoreAll ? itemList : this.props.items;
    const selectedIndex = isScoreAll ? itemIndex : selected;
    const selectedIssue = items[selectedIndex];
    const { score } = selectedIssue;
    const scoreId = score ? score.id : '';

    if (!Number.isInteger(value) && !explictNull) {
      value = score ? score.attributes.value : null;
    }

    if (typeof notes !== 'string') {
      notes = score ? score.attributes.notes : '';
    }

    const body = {
      value,
      notes,
      data_state_time: Date.now(),
      property_id: propertyId || space.attributes.property.id,
      issue_id: selectedIssue.spaceTypeIssue.attributes.issue.id,
      space_id: space.id,
    };

    if (image) {
      body.image = image;
    }

    const res = await axios({
      method: scoreId ? 'put' : 'post',
      url: `${api.score}/${scoreId}`,
      params: { inspection_id: scoreId ? null : this.props.inspectionId },
      data: body,
    });
    const { data, status } = res;
    if (status !== 200) {
      console.warn('Issue occurred saving score!', selectedIssue);
    }
    const updatedScore = data.data;
    const store = Store.instance(pluralMap[types.score]);
    // don't mutate the state directly, use immutability-helper
    const updatedItems = update(items, {
      [selectedIndex]: {
        score: {
          $set: updatedScore,
        },
        value: {
          $set: value,
        },
      },
    });

    store.upsert(updatedScore, !!scoreId);
    // prevent rendering each request until we are finished with the last one
    // otherwise updateItems will update the referenced item list and
    // only one item will change in the end
    if (isScoreAll) {
      return updatedItems;
    }
    this.props.updateItems(updatedItems);
  }

  static async getSpaceScores(issuesList, spaceTypeId, spaceId) {
    if (!Number.isInteger(spaceId)) {
      console.error(
        'getSpaceScores expects parameter spaceId to be of type Integer'
      );
      return;
    }

    const scores = await Store.get(pluralMap[types.score]);

    return scores.filter((score) => {
      if (score.attributes.space_id !== spaceId) {
        return false;
      }
      // make sure the score is for the right issue_id
      // we get orphaned scores when the space_type is changed
      const scoreIssueId = score.attributes.issue_id;
      return issuesList.some(
        (item) => item.spaceTypeIssue.attributes.issue.id === scoreIssueId
      );
    });
  }

  // Set scores on issue list items
  static async setScores(issuesList, spaceTypeId, spaceId) {
    // get all scores in the current space
    const scores = await Space.getSpaceScores(issuesList, spaceTypeId, spaceId);
    //let scoredItems = 0;
    // update spaceTypeIssues with current score value
    scores.forEach((score) => {
      const issue = issuesList.find((issue) => {
        return (
          issue.spaceTypeIssue.attributes.issue.id === score.attributes.issue_id
        );
      });
      if (Number.isInteger(score.attributes.value)) {
        issue.value = score.attributes.value;
      }
      issue.score = score;
    });
  }

  static async getSpaceTypeIssues(spaceTypeId, propertyId) {
    // check if already processed
    let cache = spaceTypeIssuesCache[spaceTypeId];
    if (cache) {
      return cache;
    }
    const currentInspection = await Store.get('inspection');
    if (!currentInspection) {
      return Promise.reject(Space.inspectionMissingError);
    }
    const spaceTypeIssues = await Store.get('spaceTypeIssues');
    const itsIssueIsTheRightCategory = (spaceTypeIssue) => {
      const issueCategoryId = spaceTypeIssue.attributes.issue.issue_category_id;
      return currentInspection.attributes.issue_category_ids.includes(
        issueCategoryId
      );
    };
    const itHasSameSpaceTypeAsSelectedSpace = (spaceTypeIssue) => {
      const id = spaceTypeIssue.attributes.space_type.id;
      return id === spaceTypeId;
    };
    const filteredIssues = spaceTypeIssues
      .filter((spaceTypeIssue) => {
        return (
          itsIssueIsTheRightCategory(spaceTypeIssue) &&
          itHasSameSpaceTypeAsSelectedSpace(spaceTypeIssue)
        );
      })
      .sort((spaceTypeIssueA, spaceTypeIssueB) => {
        return (
          (spaceTypeIssueA.attributes.issue_order || 0) -
          (spaceTypeIssueB.attributes.issue_order || 0)
        );
      });

    // cached the processed issueTypes, they won't change
    spaceTypeIssuesCache[spaceTypeId] = filteredIssues;
    return filteredIssues;
  }

  static async fillScores(space, propertyId) {
    const spaceTypeId = parseInt(space.attributes.space_type.id);
    const spaceTypeIssues = await Space.getSpaceTypeIssues(
      spaceTypeId,
      propertyId
    ).catch((err) => err);
    if (spaceTypeIssues === Space.inspectionMissingError) {
      return Space.inspectionMissingError;
    }
    const issuesList = new Array(spaceTypeIssues.length);
    // build the issuesList used in scoring
    for (let i = 0, size = issuesList.length; i < size; i++) {
      issuesList[i] = {
        spaceTypeIssue: spaceTypeIssues[i],
        score: null,
      };
    }
    await Space.setScores(issuesList, spaceTypeId, parseInt(space.id));
    return issuesList;
  }

  render() {
    if (this.state.updating) {
      return <Loading display="Updating" />;
    }
    return (
      <ScorePanel
        space={this.props.space}
        currentIssueIndex={this.state.selected}
        updateNotes={(e) => this.updateNotes(e)}
        uploadPhoto={(e) => this.uploadPhoto(e)}
        updateIssue={(value) => this.updateIssue(value)}
        scoreAllIssues={(value) => this.scoreAllUnscored(value)}
        clearAllScores={() => this.clearAllScores()}
        setCurrentIssue={(issue) => this.setCurrentIssue(issue)}
        items={this.props.items}
      />
    );
  }
}

Space.inspectionMissingOfflineError = {
  message:
    "No inspection assigned. If you created a property offline, you won't be able to set scores here until you sync",
};

Space.inspectionMissingError = {
  message: 'Assign an inspection.',
};

Space.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  checkNetwork: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  space: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  routeParts: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  inspectionId: PropTypes.string.isRequired,
};

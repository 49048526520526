import React from 'react';
import PropTypes from 'prop-types';

export default function PhotoGalleryImage({ thumbnailPath, selectImage }) {
  return (
    <div onClick={selectImage} className="photo-gallery-image-item">
      <img className="photo-gallery-image" src={thumbnailPath} />
    </div>
  );
}

PhotoGalleryImage.propTypes = {
  thumbnailPath: PropTypes.string.isRequired,
  selectImage: PropTypes.func.isRequired,
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { axios, api, auth, network, routes, routePaths } from '../config';
import Store from '../store';
import sync from '../sync';
import Header from '../components/Header';
import ViewSelector from './ViewSelector';
import Login from './Login';
import Logout from '../components/Logout';
import Loading from '../components/Loading';
import Form from './Form';
import Reports from './Reports';
import ErrorBoundary from './ErrorBoundary';

let scope;

const defaultState = {
  username: '',
  userIsAdmin: false,
  // changed when sync detects a change in network state
  isOnline: true,
  // set when sync is running
  syncCompleted: 0,
  syncTotal: 0,
  // set to true once logged in
  authorized: false,
  // set to true once data has been loaded into Store
  ready: false,
  reports: false,
};

class AppBase extends Component {
  constructor(props) {
    super(props);
    scope = this;
    scope.runningSync = false;
    scope.loading = true;
    scope.dataSet = false;
    scope.state = defaultState;
    scope.bindSyncEvents();
    scope.getUser();
  }

  bindSyncEvents() {
    sync.hook('online', (isOnline) => {
      scope.setState({ isOnline });
    });

    sync.hook('running', () => {
      if (!scope.dataSet) {
        return;
      }
      scope.loading = true;
      scope.setState({
        ready: false,
      });
    });

    sync.hook('progress', (syncCompleted, syncTotal) => {
      scope.setState({
        syncCompleted,
        syncTotal,
      });
    });

    sync.hook('finished', () => {
      if (!scope.dataSet) {
        return;
      }
      scope.loading = false;
      scope.setState({
        ready: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // handle any bad routes so the user won't end up in a weird state
    // we're just removing slashes here, but could do other things
    if (scope.props.location.pathname.length > 1) {
      const currentRoute = scope.props.location.pathname.split('/');
      const filteredRoute = currentRoute.filter(Boolean);
      if (!filteredRoute.length) {
        filteredRoute.push('view');
      }
      // we check against filterRoute + 1 because it always starts with "/"
      if (currentRoute.length !== filteredRoute.length + 1) {
        return scope.props.history.replace(`/${filteredRoute.join('/')}`);
      }
    }
    // get Data if not set
    if (scope.state.authorized && !scope.runningSync && !scope.dataSet) {
      scope.checkForSync();
    }
  }

  // get colleges and property titles
  getData() {
    axios({
      method: 'get',
      url: api.data,
      timeout: 1000 * 60 * 5,
    })
      .then((res) => {
        if (res.status !== 200 || !res.data || network.simulateOffline) {
          alert('Could not get initial data, working offline');
          // Create each store, using whatever is already in the session data
          [
            'colleges',
            'campuses',
            'properties',
            'issueCategories',
            'spaceTypes',
            'spaceTypeIssues',
            'inspection',
            'buildings',
            'levels',
            'spaces',
            'scores',
          ].forEach((key) => Store.create(key));

          this.loaded();
          return;
        }
        // TODO only mutable items should be in store
        Store.factory(
          [
            'colleges',
            'campuses',
            'properties',
            'issueCategories',
            'spaceTypes',
            'spaceTypeIssues',
          ],
          res.data
        );
        // create other stores for future lookups without replacing existing data
        ['inspection', 'buildings', 'levels', 'spaces', 'scores'].forEach(
          (key) => Store.create(key)
        );

        this.loaded();
      })
      .catch((err) => {
        alert(err.message);
        /*
      [
        'colleges',
        'properties',
        'issueCategories',
        'spaceTypes',
        'spaceTypeIssues',
        'inspection',
        'buildings',
        'levels',
        'spaces',
        'scores',
      ].forEach((key) => Store.create(key));
      */
      });
  }

  /**
   * Run the sync on app load and before we get data
   * so that we can get a fresh copy of whats in db
   */
  async checkForSync() {
    this.runningSync = true;
    const isQueued = await sync.isQueued();
    if (!isQueued) {
      this.runningSync = false;
      return this.getData();
    }

    sync.run(false).then(() => {
      this.runningSync = false;
      this.getData();
    });
  }

  loaded() {
    scope.dataSet = true;
    scope.loading = false;
    if (scope.props.location.pathname.indexOf(routes.login) > -1) {
      scope.props.history.replace(`/${routes.view}`);
    }
    scope.setState({
      authorized: true,
      ready: true,
    });
  }

  checkNetwork() {
    return sync
      .checkNetwork()
      .then((isOnline) => {
        scope.setState({ isOnline });
      })
      .catch((err) => {
        if (scope.state.isOnline) {
          scope.setState({ isOnline: false });
        }
      });
  }

  static get userId() {
    return scope.state.userId;
  }

  static get username() {
    return scope.state.username;
  }

  /**
   * Request User service
   */
  getUser() {
    axios
      .get(api.baseUrl)
      .then((res) => {
        const { data } = res;
        if (!data) {
          return;
        }
        // update auth token
        if (data.authToken) {
          auth.token = data.authToken;
        }
        if (!data.authenticated) {
          scope.loading = false;
          scope.dataSet = false;
          scope.setState({
            authorized: false,
            ready: false,
          });
          const pathname = scope.props.location.pathname;
          // make sure we redirect
          if (pathname.slice(1) !== routes.login) {
            scope.props.history.replace(`/${routes.login}`);
          }
        } else {
          scope.setState({
            userId: data.userId,
            username: data.username,
            userIsAdmin: data.userIsAdmin,
          });
          scope.checkForSync();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getAuthRoutes() {
    if (!scope.state.ready) {
      return null;
    }
    return (
      <Switch>
        <Route path={routePaths.reports} component={Reports} />
        <Route path={`/${routes.logout}`}>
          <Logout getUser={() => scope.getUser()} />
        </Route>
        <Route path={routePaths.form} component={Form} />
        <Route
          path={routePaths.view}
          render={(props) => (
            <ViewSelector
              {...props}
              isOnline={scope.state.isOnline}
              checkNetwork={scope.checkNetwork}
            />
          )}
        />
        <Redirect from="/" to={`/${routes.view}`} />
      </Switch>
    );
  }

  /**
   * Passed to login, used to set the initial state
   */
  authorize() {
    scope.loading = true;
    scope.getUser();
  }

  getLogin() {
    return (
      <Route path={`/${routes.login}`}>
        <Login authorize={scope.authorize} />
      </Route>
    );
  }

  render() {
    if (scope.loading) {
      return (
        <Loading
          syncRunning={sync.running}
          syncCompleted={scope.state.syncCompleted}
          syncTotal={scope.state.syncTotal}
        />
      );
    }
    return (
      <Fragment>
        <Header
          username={scope.state.username}
          userIsAdmin={scope.state.userIsAdmin}
          isOnline={scope.state.isOnline}
          history={scope.history}
          authorized={scope.state.authorized}
          location={scope.props.location.pathname}
        />
        <ErrorBoundary>
          {scope.state.authorized ? scope.getAuthRoutes() : scope.getLogin()}
        </ErrorBoundary>
      </Fragment>
    );
  }
}

AppBase.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

const App = withRouter(AppBase);
export default App;

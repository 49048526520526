import React from 'react';
import PropTypes from 'prop-types';

/**
 * @params {string} type
 */
export default function ReportCollegeListItem({
  collegeName,
  key,
  selectCollege,
}) {
  const clickHandler = () => selectCollege(collegeName);
  return (
    <li onClick={clickHandler} key={key} className="list-component__inspection">
      <p className="list-component__name">{collegeName}</p>
    </li>
  );
}

ReportCollegeListItem.propTypes = {
  selectCollege: PropTypes.func.isRequired,
  collegeName: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

function scoreAllBtn(showScoreAll, toggleScoreAll) {
  return (
    <button
      className="btn btn--primary"
      id="bulk-score-btn"
      onClick={toggleScoreAll}
    >
      {showScoreAll && 'Hide'} Score All
    </button>
  );
}

function clearAllBtn(clearAllScores) {
  const confirmClear = () => {
    if (
      window.confirm('Are you sure you want to clear all scores in this space?')
    ) {
      clearAllScores();
    }
  };

  return (
    <button className="btn btn--secondary" onClick={confirmClear}>
      Clear All
    </button>
  );
}

export default function SpaceInfo({
  name,
  issues,
  clearAllScores,
  showScoreAll,
  toggleScoreAll,
  spaceType,
  description = '',
}) {
  const allIssuesScored = issues.every((issue) =>
    Number.isInteger(issue.value)
  );
  const anyIssuesScored = issues.some((issue) => Number.isInteger(issue.value));

  return (
    <div className="space-info">
      <div id="bulk-score-actions">
        {allIssuesScored || scoreAllBtn(showScoreAll, toggleScoreAll)}
        {anyIssuesScored && clearAllBtn(clearAllScores)}
      </div>
      <span className="name">{name}</span>
      <span className="type">{spaceType}</span>
      <span className="description"> - {description}</span>
      <p>Scorable Items</p>
    </div>
  );
}

SpaceInfo.propTypes = {
  clearAllScores: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  issues: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  showScoreAll: PropTypes.bool.isRequired,
  spaceType: PropTypes.string.isRequired,
  toggleScoreAll: PropTypes.func.isRequired,
};

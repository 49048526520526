import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function getInput(name, state, isValid, update, setFocus, setRequired) {
  // make hook for checking the state
  let input;
  let className = 'form-group__field';
  if (false === isValid) {
    className += ' invalid-field';
  }
  if (name === 'notes') {
    input = (
      <textarea
        autoFocus={setFocus}
        name={name}
        className={className}
        onChange={update}
        value={state}
      />
    );
  } else {
    input = (
      <input
        autoFocus={setFocus}
        className={className}
        name={name}
        onChange={update}
        value={state}
      />
    );
  }
  return (
    <Fragment>
      {setRequired && <span className="rflag">*</span>}
      {input}
    </Fragment>
  );
}

export default function FormGroup({
  label,
  name,
  state,
  isUnique,
  isValid,
  update,
  setFocus,
  setRequired,
}) {
  let uniqueError = null;
  if (isUnique === false) {
    uniqueError = <div className="input-error">Name is already being used</div>;
  }
  return (
    <div className="form-group">
      <label className="required">
        {label}
        {getInput(name, state, isValid, update, setFocus, setRequired)}
      </label>
      {uniqueError}
    </div>
  );
}

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  isUnique: PropTypes.bool,
  isValid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  setFocus: PropTypes.bool,
  setRequired: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';

function buildOptions(options) {
  return options.map((option, index) => {
    return (
      <option key={index} value={option}>
        {option}
      </option>
    );
  });
}

export default function PhotoFilterSelect({
  name,
  options,
  state,
  update,
  defaultOption,
}) {
  return (
    <select
      onChange={update}
      value={state || ''}
      name={name}
      id={name}
      className="photo-gallery-filter"
    >
      <option value="">{defaultOption}</option>
      {buildOptions(options)}
    </select>
  );
}

PhotoFilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  defaultOption: PropTypes.string.isRequired,
};

import React from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { requestInterceptor, errorResponseInterceptor } from './interceptors';
import { axios, network } from './config';
import App from './containers/App';

window.toggleOffline = () => {
  network.simulateOffline = !network.simulateOffline;
  return network.simulateOffline;
};

// These hook into AJAX requests and responses to perform
// actions necessary for synchronization and authentication
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
  (response) => response,
  errorResponseInterceptor
);

const root = document.createElement('app');

render(
  <Router>
    <App />
  </Router>,
  root
);

document.body.insertBefore(root, document.body.firstElementChild);

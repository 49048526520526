import { auth, network } from './config';
import sync, { networkError } from './sync';

/**
 * This is the default request handler, it is never removed
 */
export function requestInterceptor(config) {
  // continue if it is not an AJAX request with JSON
  if (
    (!config.data && config.method != 'delete') ||
    typeof config.data === 'string'
  ) {
    return config;
  }

  config.data = config.data ? config.data : {};
  config.data.data_state_time = Date.now();
  config.data.authenticity_token = auth.token;
  let { isSyncRequest } = config.data;
  // this will be removed by JSON.stringify
  config.data.isSyncRequest = undefined;
  // If sync is running status checks or we are simulating offline.
  // When status checks are running we want to avoid a race condition
  // where this request gets through before the heartbeat that will
  // trigger a sync to happen, so until we come back online and everything
  // is synced, all requests will be backlogged.
  if (!isSyncRequest && (!sync.isOnline || network.simulateOffline)) {
    let err = new Error(networkError.message);
    config.data = JSON.stringify(config.data);
    err.config = config;
    return Promise.reject(err);
  }
  return config;
}

/**
 * Needs to avoid sending real requests while syncing is running
 * but still needs to allow sync requests to run and detect
 * if they fail to stop and preserve the queue
 */
export function backlogRequestInterceptor(config) {
  // continue if it is not an AJAX request with JSON
  if (
    (!config.data && config.method != 'delete') ||
    typeof config.data === 'string'
  ) {
    return config;
  }

  config.data = config.data ? config.data : {};
  config.data.data_state_time = Date.now();
  config.data.authenticity_token = auth.token;
  let err = new Error(networkError.message);
  config.data = JSON.stringify(config.data);
  err.config = config;
  return Promise.reject(err);
}

/**
 * Interceptor function to handle errors within AJAX requests
 * This is the default response handler, it is removed during
 * synchronization and replaced by the backlogResponseHandler
 * which will keep new requests in a local queue until consumed
 * by the sync.run method into the stored queue.
 */
export function errorResponseInterceptor(error) {
  if (error.message === networkError.message) {
    // enforce state
    sync.isOnline = false;
    // build mock request to add to our queue store
    let { method, url, data } = error.config;
    return sync.add({ method, url, data });
  } else {
    return Promise.reject(error);
  }
}

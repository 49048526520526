import App from './App';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Tooltip from '../components/Tooltip';
import ConfirmItem from '../components/ConfirmItem';
import { api } from '../config';
import { axios, reportFinalized } from './Reports';

const year = new Date().getFullYear();
// because ordered lists are messy with flex, we are generating our own
const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export default class ReportSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.resizeTextareaItems();
  }

  static getFriendlyName(name) {
    return name.replace(/_/g, ' ');
  }

  static getCSSName(name) {
    return name.replace(/_/g, '-');
  }

  static getFriendlyDate(date) {
    let d = date ? new Date(date) : new Date();
    d = d.toDateString().split(' ').slice(1);
    return `${d.slice(0, 2).join(' ')}, ${d.slice(-1)}`;
  }

  /**
   * Calls the autosizeTextarea method on each .report-item-text
   */
  resizeTextareaItems() {
    const htmlCollection = document.getElementsByClassName('report-item-text');
    if (htmlCollection.length) {
      const elems = Array.from(htmlCollection);
      elems.forEach((elem) => this.autosizeTextarea(elem));
    }
  }

  /**
   * automatically adjust textarea height to fit text
   */
  autosizeTextarea(elem) {
    elem.style.height = '20px';
    elem.style.height = `${elem.scrollHeight + 13}px`;
  }

  pushQcHistory(confirmed) {
    const lastQC = confirmed.qc.slice(-1)[0];
    if (!lastQC || lastQC.type === 'change') { return {}; }

    return {
      confirmed: {
        qc: {
          $push: [
            {
              modified_on: new Date(),
              modified_by: App.username,
              type: 'change',
            }
          ],
        }
      }
    };
  }

  updateBulletItem(index, key, elem, callback) {
    const value = elem.value;
    const bullets =
      this.state.bullet_list || this.props.content.bullet_list || [];
    let updatedBullets;

    if (!bullets[index]) {
      updatedBullets = update(bullets, {
        $push: [
          { resolved: '', confirmed: { qc: [], }, value },
        ],
      });
    } else {
      updatedBullets = update(bullets, {
        [index]:
          Object.assign(
            { [key]: { $set: value } },
            this.pushQcHistory(bullets[index].confirmed)
          )
        }
      );
    }

    this.setState(
      {
        bullet_list: updatedBullets,
      },
      () => {
        if (key === 'value') {
          this.autosizeTextarea(elem);
        } else {
          this.saveSection('bullet_list');
        }
        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  confirmBullet({ index, qc }) {
    const bullets =
      this.state.bullet_list || this.props.content.bullet_list || [];
    let confirmed = {};
    if (qc) {
      confirmed = {
        qc: {
          $push: [
            {
              modified_on: new Date(),
              modified_by: App.username,
              type: 'confirm',
            }
          ],
        },
      };
    } else {
      confirmed = {
        carryover_content: {
          $set: true,
        },
      };
    }

    let updatedBullets = update(bullets, {
      [index]: {
        confirmed: confirmed,
      },
    });
    this.setState(
      {
        bullet_list: updatedBullets,
      },
      () => {
        this.saveSection('bullet_list');
      }
    );
  }

  removeBullet(index) {
    const bullets = this.props.content.bullet_list;
    const updatedBullets = update(bullets, {
      $splice: [[index, 1]],
    });
    this.setState({ bullet_list: updatedBullets }, () =>
      this.saveSection('bullet_list')
    );
  }

  bullet(props, index, isNew) {
    const state = this.state.bullet_list;
    const resolveable = this.props.sectionType == 'Unresolved Items';
    const { value, resolved, confirmed } = props;
    const needsConfirm =
      !!value &&
      !resolved &&
      confirmed.carryover_content != undefined &&
      !confirmed.carryover_content;
    const isFinalized = reportFinalized(this.props.reportStatus);
    let stateValue = value;
    let stateResolved = resolved;
    if (state && state[index]) {
      stateValue = state[index].value;
      stateResolved = state[index].resolved;
    }
    const options = Array(20)
      .fill()
      .map((v, i) => (
        <option key={i} value={year - i}>
          {year - i}
        </option>
      ));
    const removeBullet = (e) => {
      e.preventDefault();
      if (isNew || isFinalized) {
        return;
      }
      if (!confirm('Are you sure you want to delete this item?')) {
        return;
      }
      this.removeBullet(index);
    };
    let deleteClass = 'fa fa-remove bullet-item-remove';
    if (isNew) {
      deleteClass += ' remove-disabled';
    }
    let textAreaClass = 'bullet-item-text report-item-text';
    if (needsConfirm) {
      textAreaClass += ' unconfirmed';
    }
    const scope = this;
    function handleChange(el) {
      scope.updateBulletItem(
        index,
        'value',
        el,
        () => needsConfirm && scope.confirmBullet({ index })
      );
    }
    const resolve_select = (
      <select
        value={stateResolved}
        onChange={(e) => this.updateBulletItem(index, 'resolved', e.target)}
        className="report-item-select"
        disabled={isNew || isFinalized}
      >
        <option value="">resolve</option>
        {options}
      </select>
    );

    const bullet = (
      <div key={index} className="report-list-item bullet-list-item">
        <a href="#" className={deleteClass} onClick={removeBullet}></a>
        <div className="bullet-item-letter">{alphabet[index]}</div>
        {resolveable && resolve_select}
        <textarea
          className={textAreaClass}
          value={stateValue}
          onChange={(e) => handleChange(e.target)}
          onBlur={() => this.saveSection('bullet_list')}
          disabled={!!stateResolved || isFinalized}
        />
        {value && !resolved && (
          <ConfirmItem
            confirmHandler={() => this.confirmBullet({ index })}
            qcHandler={() => this.confirmBullet({ index, qc: true })}
            confirmed={confirmed}
          />
        )}
      </div>
    );
    return bullet;
  }

  textarea(contentKey) {
    const {
      content,
      content: { confirmed },
    } = this.props;
    const value = content[contentKey];
    const id = ReportSection.getCSSName(contentKey);
    const inputId = `${id}-value`;
    const friendlyName = ReportSection.getFriendlyName(contentKey);
    const needsConfirm =
      value &&
      confirmed[contentKey].carryover_content != undefined &&
      !confirmed[contentKey].carryover_content;
    let classes = 'report-item-text';
    if (needsConfirm) {
      classes += ' unconfirmed';
    }
    const scope = this;
    function handleChange(e) {
      if (needsConfirm || !value) {
        scope.handleConfirm({ contentKey });
      }
      scope.updateState(contentKey, e.target);
    }

    return (
      <div className="report-list-item" id={id}>
        <label htmlFor={inputId} className="report-item-label capitalize">
          {friendlyName}
        </label>
        <textarea
          id={inputId}
          className={classes}
          onChange={(e) => handleChange(e)}
          onBlur={() => this.saveSection(contentKey)}
          defaultValue={value}
          disabled={reportFinalized(this.props.reportStatus)}
        />
        {value && (
          <ConfirmItem
            confirmed={confirmed[contentKey]}
            confirmHandler={() => this.handleConfirm({ contentKey })}
            qcHandler={() => this.handleConfirm({ contentKey, qc: true })}
          />
        )}
      </div>
    );
  }

  listItem(contentKey) {
    const {
      content,
      content: { confirmed },
    } = this.props;
    const value = content[contentKey];
    if (value === undefined) { return; }

    const id = ReportSection.getCSSName(contentKey);
    const inputId = `${id}-value`;
    const friendlyName = ReportSection.getFriendlyName(contentKey);
    const needsConfirm =
      value &&
      confirmed &&
      confirmed[contentKey].carryover_content != undefined &&
      !confirmed[contentKey].carryover_content;
    let classes = 'report-item-text';
    if (needsConfirm) {
      classes += ' unconfirmed';
    }
    const scope = this;
    function handleChange(e) {
      if (needsConfirm || !value) {
        scope.handleConfirm({ contentKey });
      }
      scope.updateState(contentKey, e.target);
    }

    return (
      <div key={contentKey} className="report-list-item" id={id}>
        <label htmlFor={inputId} className="report-item-label capitalize">
          {friendlyName}
        </label>
        <input
          className={classes}
          type="text"
          id={inputId}
          onChange={(e) => handleChange(e)}
          onBlur={() => this.saveSection(contentKey)}
          defaultValue={value}
          disabled={reportFinalized(this.props.reportStatus)}
        />
        {value &&
          !reportFinalized(this.props.reportStatus) && ( // hide on finalized legacy reports finalized before QC
            <ConfirmItem
              confirmed={confirmed[contentKey]}
              confirmHandler={() => this.handleConfirm({ contentKey })}
              qcHandler={() => this.handleConfirm({ contentKey, qc: true })}
            />
          )}
      </div>
    );
  }

  handleConfirm({ contentKey, qc }) {
    const { content } = this.props;
    if (qc) {
      content.confirmed[contentKey].qc.push({
        modified_on: new Date(),
        modified_by: App.username,
        type: 'confirm'
      });
    } else {
      content.confirmed[contentKey].carryover_content = true;
    }
    this.saveContent(content);
  }

  updateState(contentKey, elem, callback) {
    const value = elem.value;
    const confirmed = this.props.content.confirmed;
    const lastQC = confirmed[contentKey].qc.slice(-1)[0];
    let updatedState = { [contentKey]: value };

    if (lastQC && lastQC.type === 'confirm') {
      updatedState.confirmed = update(confirmed, {
        [contentKey]: {
          qc: {
            $push: [
              {
                modified_on: new Date(),
                modified_by: App.username,
                type: 'change',
              }
            ],
          },
        },
      });
    }

    if (elem.type === 'textarea') {
      this.setState(
        updatedState,
        () => {
          this.autosizeTextarea(elem);
          if (typeof callback === 'function') {
            callback();
          }
        }
      );
    } else if (elem.type === 'checkbox') {
      this.setState({ [contentKey]: elem.checked }, callback);
    } else {
      this.setState(updatedState, callback);
    }
  }

  saveSection(stateKey) {
    const { state } = this;
    const { content } = this.props;

    if (!stateKey || state[stateKey] === undefined) {
      return;
    }
    const stateValue = state[stateKey];
    const value =
      typeof stateValue === 'string' ? stateValue.trim() : stateValue;
    if (content[stateKey] === value) {
      return;
    }
    content[stateKey] = value;
    if (content.confirmed && state.confirmed) {
      content.confirmed[stateKey] = state.confirmed[stateKey];
    }
    this.saveContent(content);
  }

  async saveContent(content) {
    const { currentInspectionId, currentSectionId, updateSections } =
      this.props;
    const url = `${api.inspections}/${currentInspectionId}/report_sections/${currentSectionId}`;
    const res = await axios({
      method: 'put',
      url,
      async: false,
      data: {
        form_data: JSON.stringify(content),
      },
    });
    const { data } = res.data;
    if (!data) {
      // TODO make more useful and visible
      return console.error('An Error Occurred');
    }
    updateSections(data.attributes.form_data, currentSectionId, () => {
      this.resizeTextareaItems();
    });
  }

  async archiveReport() {
    const { currentInspectionId } = this.props;
    await axios({
      method: 'get',
      url: `${api.inspections}/${currentInspectionId}/archive`,
      data: { inspectionId: currentInspectionId },
    }).catch((err) => {
      console.log('There was an error archiving this inspection: ' + err);
    });
  }

  // rendered by the ./components/report-sections/:sectionType component
}

ReportSection.propTypes = {
  sectionType: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  currentSectionId: PropTypes.string.isRequired,
  currentInspectionId: PropTypes.string.isRequired,
  updateSections: PropTypes.func.isRequired,
};

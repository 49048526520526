import React from 'react';
import PropTypes from 'prop-types';
import iconSortUp from '../../assets/images/bi-sort-alpha-up.svg';
import iconSortDown from '../../assets/images/bi-sort-alpha-down.svg';
import iconChevronUp from '../../assets/images/bi-chevron-up.svg';
import iconChevronDown from '../../assets/images/bi-chevron-down.svg';
import iconX from '../../assets/images/bi-x.svg';
import { debounce } from 'lodash';

export default function ListFilters({
  categoryCounter,
  isSortAsc,
  setIsSortAsc,
  searchTerm,
  setSearchTerm,
  showUnscoredOnly,
  setShowUnscoredOnly,
  showFilters,
  setShowFilters,
}) {
  const debouncedSetSearchTerm = debounce(setSearchTerm);

  return (
    <div className="filters">
      <div className={`filter-actions ${showFilters ? '' : 'hide'}`}>
        <button
          className="btn btn--primary"
          onClick={() => setIsSortAsc(!isSortAsc)}
        >
          <img src={isSortAsc ? iconSortUp : iconSortDown} />
        </button>

        <div className="flex">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={({ target }) => debouncedSetSearchTerm(target.value)}
          />
          <a
            className="btn btn--link"
            onClick={() => debouncedSetSearchTerm('')}
            style={{ paddingLeft: '5px', marginBottom: '-2px' }}
          >
            <img src={iconX} />
          </a>
        </div>

        {categoryCounter && (
          <div className="toggle-container">
            <label htmlFor="showUnscoredToggle">Show unscored spaces</label>
            <input
              className="toggle-switch toggle-switch-on-off"
              type="checkbox"
              id="showUnscoredToggle"
              checked={showUnscoredOnly}
              onChange={() => setShowUnscoredOnly(!showUnscoredOnly)}
            />
          </div>
        )}
      </div>

      <a
        className="filter-toggle btn btn--link"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? 'Hide ' : 'Show '}
        Filters
        <img src={showFilters ? iconChevronUp : iconChevronDown} />
      </a>
    </div>
  );
}

ListFilters.propTypes = {
  categoryCounter: PropTypes.object,
  isSortAsc: PropTypes.bool.isRequired,
  setIsSortAsc: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  showUnscoredOnly: PropTypes.bool.isRequired,
  setShowUnscoredOnly: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  setShowFilters: PropTypes.func.isRequired,
};

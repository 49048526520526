import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';
import PhotoGallery from '../../containers/PhotoGallery';
import Loading from '../../components/Loading';
import { axios, api } from 'config';

const editItems = [
  'report_date',
  'college',
  'property',
  'company',
  'title',
  'street',
  'city',
  'state_full',
  'prepared_for',
  'prepared_for_alt',
  'client',
  'email',
];

export default class FrontPage extends ReportSection {
  componentDidMount() {
    this.getPhotos();
  }

  getPhotos() {
    // comment out the below if using prod data in dev
    const endpoint = `${api.inspections}/${this.props.currentInspectionId}/photos`;
    return axios.get(endpoint).then((res) => {
      const { data } = res;
      this.setState({
        photos: data,
        ready: true,
      });
    });
    // uncomment the below if using prod data in dev
    // this.setState({
    //   photos: [],
    //   ready: true,
    // });
  }

  previewPhoto(path) {
    this.setState({ preview: path });
  }

  selectImage(image) {
    this.setState(
      {
        cover_photo: {
          image_path: image.image_path,
          thumbnail_path: image.thumbnail_path,
        },
      },
      () => {
        this.saveSection('cover_photo');
      }
    );
  }

  renderPreview() {
    return (
      <div
        id="preview-modal-wrap"
        onClick={() => this.setState({ preview: null })}
      >
        <div id="preview-modal">
          <img id="preview-modal-img" src={this.state.preview} />
          <span>Click Anywhere to Close</span>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.ready) {
      return <Loading display={'Getting Image'} fullPage={false} />;
    }
    let previewModal = null;
    if (this.state.preview) {
      previewModal = this.renderPreview();
    }
    const { cover_photo: coverPhoto } = this.props.content;
    let coverPhotoImage = '';
    if (!coverPhoto || !coverPhoto.image_path) {
      coverPhotoImage = (
        <span className="report-item-img custodial-photo fa fa-image" />
      );
    } else {
      coverPhotoImage = (
        <img
          className="report-item-img custodial-photo"
          src={coverPhoto.thumbnail_path}
          onClick={() => this.previewPhoto(coverPhoto.image_path)}
        />
      );
    }
    return (
      <Fragment>
        <PhotoGallery
          photos={this.state.photos}
          selectImage={(photo) => this.selectImage(photo)}
          buttonText="Select Cover Photo"
        />
        <div className="report-list-item" id="cover-photo">
          <div className="report-item-label capitalize">Cover Photo</div>
          <div className="report-item-photo custodial-photo-wrap">
            {coverPhotoImage}
          </div>
        </div>
        {editItems.map((name) => this.listItem(name))}
        {this.textarea('client_address')}
        {previewModal}
      </Fragment>
    );
  }
}

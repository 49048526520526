'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { axios, auth, api } from '../config';
import qs from 'qs';

const defaultState = {
  'user[email]': '',
  'user[password]': '',
  'user[remember_me]': '0',
  error: '',
};

let scope = this;

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    scope = this;
    scope.state = defaultState;
  }

  login(e) {
    e.preventDefault();
    axios({
      timeout: 30000,
      method: 'post',
      url: `${api.login}`,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        'utf-8': '✓',
        'user[email]': scope.state['user[email]'],
        'user[password]': scope.state['user[password]'],
        'user[remember_me]': scope.state['user[remember_me]'],
        authenticity_token: auth.token,
      }),
    })
      .then((res) => {
        if (!res.data.authenticated) {
          scope.setState({ error: 'Could not sign in' });
        } else {
          auth.token = res.data.authToken;
          this.props.authorize();
        }
      })
      .catch((err, res) => {
        scope.setState({ error: 'Invalid email or password' });
      });
  }

  setInputValue(e) {
    let newState = {
      error: '',
    };
    if (e.target.type === 'checkbox') {
      newState[e.target.name] = e.target.checked ? 1 : 0;
    } else {
      newState[e.target.name] = e.target.value;
    }
    scope.setState(newState);
  }

  render() {
    let error = null;
    if (scope.state.error) {
      error = <div className="input-error">{scope.state.error}</div>;
    }
    return (
      <div id="login-wrap">
        <div id="login">
          <div className="icon"></div>
          <form className="new_user" id="new_user">
            <input name="utf8" type="hidden" value="✓" />
            <div className="form-group">
              <label className="form-group__label" htmlFor="user_email">
                Email
              </label>
              <input
                autoFocus="autofocus"
                required
                autoComplete="email"
                className="form-group__field"
                type="email"
                name="user[email]"
                id="user_email"
                state={scope.state['user[email]']}
                onChange={scope.setInputValue}
              />
            </div>
            <div className="form-group">
              <label className="form-group__label" htmlFor="user_password">
                Password
              </label>
              <input
                autoComplete="current-password"
                required
                className="form-group__field"
                type="password"
                name="user[password]"
                id="user_password"
                state={scope.state['user[password]']}
                onChange={scope.setInputValue}
              />
            </div>
            <div className="form-group form-group--inline">
              <label className="form-group__label" htmlFor="user_remember_me">
                Remember me
              </label>
              <input
                className="form-group__field"
                onChange={scope.setInputValue}
                state={scope.state['user[remember_me]']}
                type="checkbox"
                value="1"
                name="user[remember_me]"
                id="user_remember_me"
              />
            </div>
            <div className="form-group form-group--inline">
              <a href="/users/password/new" className="forgot-password">
                Forgot password?
              </a>
            </div>
            <div className="form-group">{error}</div>
            <div className="form-actions">
              <input
                type="submit"
                name="commit"
                value="Log in"
                className="btn btn--primary"
                data-disable-with="Log in"
                onClick={(e) => this.login(e)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  authorize: PropTypes.func.isRequired,
};

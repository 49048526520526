import React from 'react';

export default function Legend() {
  return (
    <div id="counter-legend">
      <div className="counter-legend-item">
        <div className="custodial-counter"></div>
        <span className="counter-legend-text">Custodial</span>
      </div>
      <div className="counter-legend-item">
        <div className="maintenance-counter"></div>
        <span className="counter-legend-text">Maintenance</span>
      </div>
    </div>
  );
}

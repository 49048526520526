import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
  api,
  axios,
  contextProgression,
  routes,
  pluralMap,
  types,
} from '../config';
import { clone, compactObj, getViewPath } from '../utils';
import Campus from '../components/forms/Campus';
import Property from '../components/forms/Property';
import Building from '../components/forms/Building';
import Level from '../components/forms/Level';
import Space from '../components/forms/Space';
import Store from '../store';

const formMap = {
  [types.campus]: Campus,
  [types.property]: Property,
  [types.building]: Building,
  [types.level]: Level,
  [types.space]: Space,
};

const formStates = {};

formStates.campus = {
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip: '',
};

formStates.property = {
  name: '',
  street_address: '',
  city: '',
  state: '',
  zip: '',
};

formStates.building = Object.assign({ notes: '' }, formStates.property);

formStates.level = {
  name: '',
};

formStates.space = {
  name: '',
  space_type_id: '',
  level_id: '',
  description: '',
};

const requiredFields = {
  campus: {
    name: 1,
    street_address: 1,
    city: 1,
    state: 1,
    zip: 1,
  },
  property: {
    name: 1,
    street_address: 1,
    city: 1,
    state: 1,
    zip: 1,
  },
  building: {
    name: 1,
  },
  level: {
    name: 1,
  },
  space: {
    name: 1,
    space_type_id: 1,
    level_id: 1,
  },
};

// caches spaceTypes after initialization
const spaceTypes = [];
let levels = [];
const defaultState = {
  // whether or not the name is unique for the parent
  isUnique: true,
  // disable submit button on open
  isDisabled: true,
  // will toggle submit if > 0 and will show messages on inputs
  isValid: {},
  // used by the SpaceForm component
  spaceTypes: [],
  levels: [],

  // used for storing values and comparing before editing
  originalValues: {},
  // stored as key,value => college_id, 2
  parentRelationship: [],
};

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {},
      defaultState,
      clone(formStates),
      { spaceTypes }, // use spaceTypes cache if available
      { levels }
    );
    this.ready = true;
    this.setSpaceTypes();
    this.setLevels();
  }

  componentDidMount() {
    const { action } = this.props.match.params;
    if (action === routes.create) {
      return;
    }
    this.ready = false;
    this.getEditData();
  }

  setSpaceTypes() {
    // if it is cached already, don't rebuild
    if (spaceTypes.length > 0) {
      return;
    }
    Store.get('spaceTypes').then((items) => {
      items.forEach((spaceType) => {
        spaceTypes.push({
          value: spaceType.id,
          name: spaceType.attributes.name,
        });
      });
      this.setState({ spaceTypes });
    });
  }

  setLevels() {
    // don't cache because unlike spacetypes, levels can change
    Store.get('levels').then((items) => {
      levels = items.map((level) => {
        return {
          value: level.id,
          name: level.attributes.name,
        };
      });
      this.setState({ levels });
    });
  }

  async getEditData() {
    const { type, id } = this.props.match.params;
    // find data for current type
    const item = await Store.get(pluralMap[type]).then((items) => {
      return items.find((item) => item.id === id);
    });
    const currentContextIndex = contextProgression.indexOf(type);
    const parentContext = contextProgression[currentContextIndex - 1];
    const parentId = item.relationships[parentContext].data.id;

    const values = {};
    if (type === types.space) {
      const { name, space_type, description } = item.attributes;
      values.name = name;
      values.space_type_id = space_type.id.toString();
      values.level_id = item.relationships.level.data.id;
      values.description = description;
    } else {
      Object.assign(values, formStates[type], compactObj(item.attributes));
    }
    this.ready = true;
    this.setState({
      originalValues: values,
      [type]: values,
      parentRelationship: [`${parentContext}_id`, parentId],
    });
  }

  /**
   * Make sure name is unique for parent
   */
  async ensureUnique(name, type, parentType, parentId) {
    const items = await Store.get(pluralMap[type]);
    const currentItems = items.filter(
      (item) => item.relationships[parentType].data.id === parentId
    );
    return !currentItems.some((item) => item.attributes.name === name);
  }

  async submit(e) {
    e.preventDefault();
    this.setState({ isDisabled: true });
    const { params } = this.props.match;
    const {
      action,
      // id is only set on updates
      id,
      type,
    } = params;
    const { [type]: values, originalValues, parentRelationship } = this.state;
    // find the parent context
    const required = requiredFields[type];
    const isUpdate = action === routes.update;
    const inspection = await Store.get('inspection');
    const data = {
      isMobileSubmit: true,
      inspection: inspection ? inspection.id : null,
    };
    const currentTypeIndex = contextProgression.indexOf(type);
    const parentType = contextProgression[currentTypeIndex - 1];
    let parentId = null;
    let parentIdField = null;
    let method = 'POST';
    let url = api[type];

    // keep track of failed validations
    const isValid = {};
    const failed = false;

    // populate the data
    Object.keys(values).forEach((key) => {
      const value = values[key] && values[key].trim();

      // skip optional values that weren't updated
      if (!required[key] && isUpdate && value === originalValues[key]) {
        return;
      }
      if (required[key] && value.length === 0) {
        isValid[key] = false;
        failed = true;
        return;
      }
      isValid[key] = true;
      data[key] = value;
    });

    if (failed) {
      this.setState({
        isDisabled: false,
        isValid,
      });
      return;
    }

    // add the parent id field e.g. "college_id" to our data
    if (isUpdate) {
      // this is coming from getEditData
      [parentIdField, parentId] = parentRelationship;
      method = 'PUT';
      url += `/${id}`;
    } else {
      parentIdField = `${parentType}_id`;
      parentId = params.parentId;
      data[parentIdField] = parentId;
    }

    if (originalValues.name != values.name.trim()) {
      let isUnique = await this.ensureUnique(
        values.name.trim(),
        type,
        parentType,
        parentId
      );
      if (!isUnique) {
        this.setState({
          isUnique,
          isValid: {
            name: false,
          },
        });
        return;
      }
    }

    axios({
      method,
      url,
      data,
    })
      .then((res) => {
        const { data, status } = res;
        if (status !== 200) {
          console.warn(`Issue occurred saving ${type}!`, data);
        }

        const store = Store.instance(pluralMap[type]);
        store.upsert(data.data, isUpdate);
      })
      .catch((err) => {
        const { data } = err.response;
        console.warn(`Issue occurred saving ${type}!`, data.message);
        if (err.message !== 'Network Error') {
          alert(data.message);
        }
      })
      .finally(() => {
        let pathArgs = null;
        if (isUpdate) {
          pathArgs = [id, type];
        } else {
          pathArgs = [parentId, parentType];
        }
        // redirect to parent
        getViewPath(pathArgs[0], pathArgs[1]).then((path) => {
          this.props.history.replace(path);
        });
      });
  }

  updateValue(e) {
    const { value, name } = e.currentTarget;
    const { type } = this.props.match.params;
    const required = requiredFields[type];
    const isValid = Object.assign({}, isValid);
    const newFormState = update(this.state[type], {
      $merge: {
        [name]: value,
      },
    });

    if (required[name] && value.trim() === '') {
      isValid[name] = false;
    }
    Object.keys(required).forEach((field) => {
      if (field === name) {
        return;
      }
      // update valid state for all items
      if (newFormState[field].trim() === '') {
        isValid[field] = false;
      }
    });

    this.setState({
      isUnique: true,
      isDisabled: false,
      isValid,
      [type]: newFormState,
    });
  }

  goBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  destroy(e) {
    e.preventDefault();
    if (
      !window.confirm(
        'This space cannot be restored without an Admin. Do you really want to delete this space?'
      )
    ) {
      return;
    }
    const parentId = this.state.parentRelationship[1];
    const { params } = this.props.match;
    const { type, id } = params;
    const url = api[type] + `/${id}`;
    const method = 'DELETE';
    axios({
      method,
      url,
    }).then((res) => {
      const { data, status } = res;
      if (status !== 200) {
        console.warn(`Issue occurred deleting ${type}!`, data);
      }
      const store = Store.instance(pluralMap[type]);
      store.delete(data.data);
      getViewPath(parentId, 'level').then((path) => {
        this.props.history.replace(path);
      });
    });
  }

  render() {
    if (!this.ready) {
      return null;
    }
    const { action, type } = this.props.match.params;
    let title = '';
    if (action === 'new') {
      title = 'Create';
    } else {
      title = 'Update';
    }
    const formComponent = formMap[type];
    const className = `crud-component ${pluralMap[type]}`;

    let props = {
      action,
      spaceTypes,
      levels,
      isUnique: this.state.isUnique,
      isDisabled: this.state.isDisabled,
      isValid: this.state.isValid,
      updateValue: (e) => this.updateValue(e),
      state: this.state[type],
      send: (e) => this.submit(e),
      cancel: (e) => this.goBack(e),
    };
    if (type === 'space') {
      props = {
        ...props,
        destroy: (e) => this.destroy(e),
      };

      if (action == 'new') {
        props.state['level_id'] = this.props.match.params.parentId;
      }
    }

    return (
      <div className={className}>
        <h2 className="capitalize">
          {title} {type}
        </h2>
        {formComponent(props)}
      </div>
    );
  }
}

Form.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

export default function Loading({
  display,
  syncRunning,
  syncCompleted,
  syncTotal,
  fullPage = true,
}) {
  let counter = null;
  if (syncRunning) {
    counter = (
      <div id="sync-counter">
        {syncCompleted} of {syncTotal} items
      </div>
    );
  }
  let text = syncRunning ? 'Syncing' : 'Loading';
  if (display) {
    text = display;
  }
  return (
    <div id="loading" className={fullPage ? 'full-page' : ''}>
      <div id="loading-wrap">
        <div id="loading-text">{text} Data</div>
        {counter}
        <div id="loading-spinner"></div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  display: PropTypes.string,
  syncRunning: PropTypes.bool,
  syncCompleted: PropTypes.number,
  syncTotal: PropTypes.number,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logger } from 'config';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.log(error);
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div id="error-handler">
        <h1>Something went wrong.</h1>
        <p>
          A notification has been sent to our team and we will investigate the
          issue
        </p>
        <Link to="/" onClick={() => this.setState({ hasError: false })}>
          Return Home
        </Link>
      </div>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
};

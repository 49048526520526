import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  api,
  axiosDefault,
  contextProgression,
  network,
  routes,
  types,
  pluralMap,
} from '../config';
import { Link } from 'react-router-dom';
import Legend from '../components/Legend';
import List from '../components/List';
import Loading from '../components/Loading';
import Space from './Space';
import Breadcrumb from '../components/Breadcrumb';
import Store from '../store';
import { ListFilterProvider } from '../contexts/ListFilterProvider';

const defaultState = {
  isOnline: true,
  ready: false,
  loadingProperty: false,
  // set to true when cannot find property data
  notLoaded: false,
  errorMessage: null,
  // set the current context of the data to be displayed
  context: types.college,
  previousContext: null,
  // the list items that will be populated
  items: [],
  parentItem: null,
  college: null,
  property: null,
  building: null,
  level: null,
  space: null,
  inspection: null,
};

/**
 * Return a new state to avoid mutation
 */
function getUpdateState(inject) {
  return Object.assign({}, defaultState, inject);
}

function getRouteParts(params) {
  return Object.keys(params).map((param) => params[param]);
}

const issueCategories = {};
let scope = this;

class ViewSelector extends Component {
  constructor(props) {
    super(props);
    scope = this;
    scope.loaded = false;
    scope.props = props;
    scope.state = Object.assign({}, defaultState);
    // will bypass componentDidUpdate attempt to push history
    scope.bypassRouting = false;
  }

  /**
   * Direct routes when reloading or mounting
   */
  componentDidMount() {
    const { params } = this.props.match;
    const types = Object.keys(params);
    // getting college or campus
    if (types.length <= 2) {
      this.configureState();
    } else {
      const { campus, property } = params;
      // get property data from id
      this.loadProperty(campus, property).then((loaded) => {
        if (loaded) {
          this.configureState();
        } else {
          this.setState({ notLoaded: true });
        }
      });
    }
  }

  /**
   * Ensures that property data has been loaded or shows error message
   */
  async checkExistingPropertyData(propertyId) {
    const buildings = await Store.get(pluralMap[types.building]);
    const exists = buildings.some(
      (row) => row.relationships.property.data.id === propertyId
    );
    scope.setState({ loadingProperty: false });
    return exists;
  }

  /**
   * Get all necessary property data
   */
  async loadProperty(campusName, propertyName) {
    scope.setState({ loadingProperty: true });
    const searchParams = this.props.location.search;
    const inspectionParam = searchParams.match(/inspection=(\d*)/);
    const campuses = await Store.get(pluralMap[types.campus]);
    const properties = await Store.get(pluralMap[types.property]);
    const campus = campuses.find(
      (row) => row.attributes.name === decodeURIComponent(campusName)
    );
    const campusId = campus.id;
    const property = properties.find(
      (row) =>
        row.relationships.campus.data.id === campusId &&
        row.attributes.name === decodeURIComponent(propertyName)
    );
    const res = await axiosDefault({
      method: 'get',
      url: `${api.property}/${property.id}`,
      params: { inspection: inspectionParam ? inspectionParam[1] : null },
      timeout: 1000 * 10 * 60,
    }).catch((err) => {
      if (err.message !== 'Network Error') {
        alert(err.message);
      }
      return false;
    });
    if (
      res === false ||
      !res.data ||
      res.status !== 200 ||
      network.simulateOffline
    ) {
      console.warn('Could not load new property data');
      return scope.checkExistingPropertyData(property.id);
    }
    Store.factory(
      ['inspection', 'buildings', 'levels', 'spaces', 'scores'],
      res.data
    );
    scope.setState({
      loadingProperty: false,
      inspection: res.data.inspection.data,
    });
    return true;
  }

  configureState() {
    // build a hash table of all issueCategories by [id: type]
    Store.get('issueCategories').then((categories) => {
      categories.forEach(({ attributes }) => {
        issueCategories[attributes.id] = attributes.category_type;
      });
    });
    scope.setRouterState();
  }

  /**
   * Setting the state or changing the history will trigger an updated state.
   * We want to watch for navigation changes from the browser, if bypassRouting
   * is "true" then we know it was triggered programmatically, otherwiser it was
   * the browser and we want to update the router state entirely
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !scope.bypassRouting &&
      scope.props.location.pathname !== prevProps.location.pathname
    ) {
      scope.setRouterState();
    }
    scope.bypassRouting = false;
  }

  async getSpaceScoreCount(level, spaces) {
    const inspection = await Store.get('inspection');
    const ids = spaces.map((space) => space.id);
    const propertyId = level.relationships.property.data.id;
    // For Two Way Sync
    // Update store with newest score data before Space.fillScores()
    if (scope.props.isOnline) {
      await axiosDefault({
        method: 'get',
        url: `${api['level']}/${level.id}/scores`,
        params: { inspection: inspection ? inspection.id : null },
      }).then((res) => {
        Store.instance('scores').upsertArray(res.data.data);
      });
    }
    // get a list of all the issue ids used in each space's scores
    // scoreItem = {space_type: {...}, score: {...}, value: null|int}
    const scoreItemsPromises = spaces.map((space) =>
      Space.fillScores(space, propertyId)
    );
    // an array of spaces and their score items
    const spaceScores = await Promise.all(scoreItemsPromises);

    if (
      spaceScores.some(
        (spaceScore) => spaceScore === Space.inspectionMissingError
      )
    ) {
      if (!scope.props.isOnline) {
        alert(Space.inspectionMissingOfflineError.message);
      } else {
        alert(Space.inspectionMissingError.message);
      }
      return;
    }

    // a hash lookup by [spaceId: categoryType] that returns a count
    const spaceCategoryCounter = {};
    ids.forEach((id) => {
      spaceCategoryCounter[id] = {
        maintenance: {
          scored: 0,
          total: 0,
        },
        custodial: {
          scored: 0,
          total: 0,
        },
      };
    });

    // build a hash of category by score id [id: issueCategory]
    spaces.forEach((space, index) => {
      spaceScores[index].forEach((scoreItem) => {
        const category =
          issueCategories[
            scoreItem.spaceTypeIssue.attributes.issue.issue_category_id
          ];
        spaceCategoryCounter[space.id][category].total += 1;
        if (Number.isInteger(scoreItem.value)) {
          spaceCategoryCounter[space.id][category].scored += 1;
        }
      });
    });
    return spaceCategoryCounter;
  }

  /**
   * TL;DR This handles the "back" and "forward" browser buttons and component state
   *
   * This will rebuild the state of our component based off the URI components.
   * Our home page being the "college" view, this is all based off of the
   * config.types which we use to create an ordered array "contextProgression".
   * See the state object, if an item has ancestors, they will be kept in the state
   * object, accessible by "scope.state[<types>]" and the immediate parent will
   * be parentItem.
   *
   */
  async setRouterState() {
    const params = scope.props.match.params;
    const routeParts = getRouteParts(params);
    // load initial context
    if (!routeParts.length) {
      const context = contextProgression[0];
      const initialStore = pluralMap[context];
      Store.get(initialStore).then((items) => {
        scope.setState(getUpdateState({ items, context, ready: true }));
      });
      return;
    }

    // create the updated state object
    const update = getUpdateState({ ready: true });

    // build routes from each context progression
    for (let i = 0, size = routeParts.length; i < size; i++) {
      const context = contextProgression[i];
      const route = decodeURIComponent(routeParts[i]);
      const store = Store.get(pluralMap[context]);
      const items = await store;
      const parentItem = items.find((item) => {
        if (update.previousContext) {
          return (
            item.relationships[update.previousContext].data.id ===
              update.parentItem.id && item.attributes.name === route
          );
        } else {
          return item.attributes.name === route;
        }
      });
      if (!parentItem) {
        console.warn('Route not found!', scope.props.location.pathname);
        // try to find an existing ancestor route
        const prevRoute = routeParts.slice(0, -1).join('/');
        return scope.props.history.replace(`/view/${prevRoute}`);
      }

      // just add the parentItem and continue through the progression
      if (i + 1 !== size) {
        update.previousContext = context;
        update.parentItem = parentItem;
        update[parentItem.type] = parentItem;
        // set item type
        continue;
      }
      const nextContext = contextProgression[i + 1];
      const nextStore = Store.instance(pluralMap[nextContext]);
      if (nextContext === types.score) {
        update.items = await Space.fillScores(parentItem, update.property.id);
        if (update.items === Space.inspectionMissingError) {
          alert(Space.inspectionMissingError.message);
          return;
        }
      } else if (
        scope.props.isOnline &&
        [types.space, types.level, types.building].includes(nextContext)
      ) {
        const params = {};
        params[`${parentItem.type}_id`] = parentItem.id;

        await axiosDefault({
          method: 'get',
          url: `${api[nextContext]}`,
          params: params,
        }).then((res) => {
          nextStore.upsertArray(res.data.data);
          update.items = res.data.data;
        });
      } else {
        update.items = await nextStore.get().then((storeItems) => {
          return storeItems.filter((item) => {
            return parentItem.id === item.relationships[context].data.id;
          });
        });
      }
      update.categoryCounter =
        nextContext === types.space
          ? await this.getSpaceScoreCount(parentItem, update.items)
          : null;
      update.parentItem = parentItem;
      update[parentItem.type] = parentItem;
      update.previousContext = context;
      update.context = nextContext;
      if (![types.campus, types.property].includes(nextContext)) {
        update.inspection = await Store.get('inspection');
      }

      // we don't want to trigger another call to setRouterState
      scope.bypassRouting = true;
      scope.setState(update);
    }
  }

  /**
   * When any list item is clicked on it will change the context of our
   * state, all that logic happens here, but only for user actions.
   *
   * @params {object} selectedItem - a single item from the store that is being inspected
   * @params {string} nextContext - the context we will be changing to
   */
  async setContext(selectedItem, nextContext) {
    const inspection = await Store.get('inspection');
    const currentContext = scope.state.context;
    const store = Store.instance(pluralMap[nextContext]);
    let items;
    if (nextContext === types.score) {
      if (scope.props.isOnline) {
        await axiosDefault({
          method: 'get',
          url: `${api['space']}/${selectedItem.id}/scores`,
          params: { inspection: inspection.id },
        }).then((res) => {
          store.upsertArray(res.data.data);
        });
      }
      items = await Space.fillScores(selectedItem, scope.state.property.id);
      if (items === Space.inspectionMissingError) {
        alert(Space.inspectionMissingError.message);
        return;
      }
    } else {
      items = await store.get().then((storeItems) => {
        return storeItems.filter((item) => {
          return selectedItem.id === item.relationships[currentContext].data.id;
        });
      });
    }

    const categoryCounter =
      nextContext === types.space
        ? await this.getSpaceScoreCount(selectedItem, items)
        : null;
    let path = `${scope.props.location.pathname}/`.replace(/^\/\//, '/');
    path += encodeURIComponent(selectedItem.attributes.name);
    // do not trigger router state, we will handle it manually
    scope.bypassRouting = true;
    // we don't need a new state here, everything is sequential in the progression
    scope.setState(
      {
        categoryCounter,
        // keep track of the items that got us here
        [selectedItem.type]: selectedItem,
        parentItem: selectedItem,
        // update to the next context
        context: nextContext,
        previousContext: scope.state.context,
        items,
      },
      () => {
        // update routes
        scope.props.history.push(path);
      }
    );
  }

  /**
   * OnClick handler bound to each list item that triggers context change
   * @params {object} selectedItem - a single item from the store that is being inspected
   */
  async selectionHandler(selectedItem) {
    const currentIndex = contextProgression.indexOf(selectedItem.type);
    const nextContext = contextProgression[currentIndex + 1];
    await scope.props.checkNetwork();
    if (selectedItem.type === types.property) {
      const loaded = await scope.loadProperty(
        scope.state.campus.attributes.name,
        selectedItem.attributes.name
      );
      // the property could not be loaded, check or fail
      if (loaded) {
        scope.setContext(selectedItem, nextContext);
      } else {
        scope.setState({ notLoaded: true });
      }
      return;
    }
    scope.setContext(selectedItem, nextContext);
  }

  /**
   * Handler to trigger loading a form for the current context.
   * i.e. The current context is property, this will load the PropertyForm
   */
  goToForm() {
    const { context, parentItem } = scope.state;
    scope.props.history.push(`/${routes.create}/${context}/${parentItem.id}`);
  }

  setPreviousContext(e) {
    e.preventDefault();
    let path = scope.props.location.pathname.replace(/^\/\//, '/').split('/');
    path.pop();
    path = path.join('/');
    scope.props.history.push(path);
  }

  getRenderComponent() {
    const { items } = scope.state;
    let editFormPath = '';
    let parentId, parentType, parentName;
    if (scope.state.parentItem && scope.state.context !== types.campus) {
      parentId = scope.state.parentItem.id;
      parentType = scope.state.parentItem.type;
      parentName = scope.state.parentItem.attributes.name;
      editFormPath = `/${routes.update}/${parentType}/${parentId}`;
    }
    const routeParts = getRouteParts(scope.props.match.params);
    const breadcrumbComponent = (
      <Breadcrumb
        routeParts={routeParts}
        editFormPath={editFormPath}
        propertyName={
          scope.state.inspection &&
          scope.state.inspection.attributes.property_name
        }
      />
    );
    if (scope.state.context === types.score) {
      if (!items.length) {
        return (
          <div className="inspection-component">
            <h5>This space has no issues for the current inspection.</h5>
            <Link
              to="#"
              className="btn btn--secondary"
              onClick={() => scope.props.history.goBack()}
            >
              Go Back
            </Link>
          </div>
        );
      }
      return (
        <Fragment>
          {breadcrumbComponent}
          <div className="inspection-component">
            <Link
              id="back-nav"
              to=""
              onClick={(e) => this.setPreviousContext(e)}
            >
              Back to {scope.state.level.attributes.name}
            </Link>
            <Legend />
            <Space
              checkNetwork={scope.props.checkNetwork}
              isOnline={scope.state.isOnline}
              propertyId={scope.state.property.id}
              routeParts={routeParts}
              space={scope.state.space}
              items={items}
              updateItems={(updatedItems) =>
                scope.setState({ items: updatedItems })
              }
              inspectionId={scope.state.inspection.id}
            />
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {breadcrumbComponent}
        <List
          type={scope.state.context}
          categoryCounter={scope.state.categoryCounter}
          selectionHandler={scope.selectionHandler}
          items={items}
          goToForm={scope.goToForm}
          inspection={scope.state.inspection}
          parentItem={scope.state.parentItem}
        />
      </Fragment>
    );
  }

  render() {
    // make sure our component is ready
    if (!scope.state.ready) {
      return <Loading display="Loading" />;
    }
    if (scope.state.loadingProperty) {
      return <Loading display="Loading Property" />;
    }
    if (scope.state.notLoaded) {
      return (
        <div className="inspection-component" id="not-loaded">
          <h5>
            This Property has not yet been loaded and you are offline. You will
            need to try again when online.
          </h5>
          <Link
            to="#"
            className="btn btn--secondary"
            onClick={() => {
              scope.setState({ notLoaded: false });
              scope.props.history.replace(
                `/view/${scope.props.match.params.college}`
              );
            }}
          >
            Go Back
          </Link>
        </div>
      );
    }

    return (
      <ListFilterProvider
        categoryCounter={scope.state.categoryCounter}
        items={scope.state.items}
        type={scope.state.context}
      >
        {this.getRenderComponent()}
      </ListFilterProvider>
    );
  }
}

export default ViewSelector;

ViewSelector.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired,
  checkNetwork: PropTypes.func.isRequired,
};

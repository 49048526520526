import Store from './store';
import { contextProgression, pluralMap, routes } from './config';

/**
 * @example [3,2,1].sort(sortAscending) => [1,2,3]
 */
export function sortAscending(n1, n2) {
  return n1 - n2;
}

/**
 * @example ["b","a","c"].sort(sortAbc) => ["a","b","c"]
 */
export function sortAbc(str1, str2) {
  var s1 = str1.toUpperCase(); // ignore upper and lowercase
  var s2 = str2.toUpperCase(); // ignore upper and lowercase

  if (s1 < s2) {
    return -1;
  }
  if (s1 > s2) {
    return 1;
  }
  return 0;
}

/**
 * @example humanize("snake_case_words") => Snake case words
 */
export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

/**
 * Remove keys with value null from object
 * @params {object}
 * @example compactObj({'a': 1, 'b': null, 'c': 2}) => {'a': 1, 'c': 2}
 */
export function compactObj(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

/**
 * Deep clone an object
 * @params {object}
 */
export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Will work backwards through the config.contextProgression
 * and build a route path that you can redirect to, in order
 * to set a state
 * @params {string} parentId
 * @params {string} parentType
 * @example
 *  getViewPath(props.match.params).then((routerPath) => {
 *    props.history.push(routerPath);
 *  });
 */
export async function getViewPath(parentId, parentType) {
  if (typeof parentId !== 'string' || !parentId.length) {
    throw new Error(
      'getPath expects parameter 1 parentId to be ' +
        'of type "string" with length > 0!'
    );
  }
  if (typeof parentType !== 'string' || !parentType.length) {
    throw new Error(
      'getPath expects parameter 2 parentType to be ' +
        'of type "string" with length > 0!'
    );
  }

  const routeParts = [];
  const items = await Store.get(pluralMap[parentType]);
  const parentItem = items.find((item) => item.id === parentId);
  const stateIndex = contextProgression.indexOf(parentType);
  const progression = contextProgression.slice(0, stateIndex);
  const { relationships } = parentItem;

  // we need to work backwards
  progression.reverse();
  routeParts.push(encodeURIComponent(parentItem.attributes.name));

  // we need scope of the ancestor to access the relationships
  let ancestor;
  for (let i = 0, size = progression.length; i < size; i++) {
    const type = progression[i];
    const storeKey = pluralMap[type];
    let items, itemId;
    if (i === 0) {
      itemId = relationships[type].data.id;
      items = await Store.get(storeKey);
    } else {
      itemId = ancestor.relationships[type].data.id;
      items = await Store.get(storeKey);
    }
    ancestor = items.find((item) => item.id === itemId);
    routeParts.push(encodeURIComponent(ancestor.attributes.name));
  }
  return `/${routes.view}/${routeParts.reverse().join('/')}`;
}

// make sure every item in isValid is true
export function ensureAllValid(isValid) {
  return Object.keys(isValid).every((key) => isValid[key]);
}

export function validTwoDecimalPlaces(value) {
  return (
    !value.includes('.') ||
    (value.includes('.') && value.split('.')[1].length <= 2)
  );
}

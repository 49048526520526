import { isIOS } from './config';

const keyPrefix = 'mo-appa-';

if (isIOS) {
  window.actions = {
    fetchedDataResults: (obj) => {
      const { key, data } = obj;
      const resolverQueueItem = Store.storage(key).queue.shift();
      resolverQueueItem(data);
    },
  };
}

export default class Store {
  /**
   * @params {string} key - the hash key of the stored object
   */
  constructor(key) {
    this.key += key;
    this.cache = null;
    this.store = isIOS ? webkit.messageHandlers : sessionStorage;

    Store.storageIndex[this.key] = this;
    // Add to index to make stores universally available
    Store.index[key] = this;
  }

  /**
   * Helper method for "new" avoidance
   */
  static create(key) {
    return new Store(key);
  }

  /**
   * expects an array of items to be found in the BOOTSTRAP_DATA top level
   */
  static factory(keys, data) {
    keys.forEach((key) => {
      let store = Store.create(key);
      store.set(data[key].data);
    });
  }

  static instance(storeKey) {
    return Store.index[storeKey];
  }

  // These are all accessed with the prefix keys
  static storage(key) {
    return Store.storageIndex[key];
  }

  static findOrSet(key, value) {
    let store = Store.create(key);
    store.get(key).then((exists) => {
      if (!exists) {
        store.set(value);
      }
    });
  }

  static get(storeKey) {
    let instance = Store.instance(storeKey);
    if (!instance) {
      throw new Error(
        `Store "${storeKey}" does not exist, has it been created yet?`
      );
    }
    return instance.get();
  }

  async upsert(entry, attemptUpdate = true) {
    // cache should be set at this point
    if (this.cache === null) {
      throw new Error(`Store.cache is not set [${this.key}]`);
    }
    const store = this.cache;
    let entryIndex = -1;
    if (attemptUpdate) {
      entryIndex = store.findIndex((item) => {
        return item.id === entry.id;
      });
    }
    if (entryIndex === -1) {
      store.push(entry);
    } else {
      store[entryIndex] = entry;
    }
    this.set(store);
  }

  upsertArray(entries) {
    entries.forEach((entry) => {
      this.upsert(entry);
    });
  }

  async delete(entry) {
    // cache should be set at this point
    if (this.cache === null) {
      throw new Error(`Store.cache is not set [${this.key}]`);
    }
    const store = this.cache;
    const entryIndex = store.findIndex((item) => {
      return item.id === entry.id;
    });

    delete store[entryIndex];
    this.set(store);
  }

  get() {
    if (!isIOS) {
      if (this.cache === null) {
        this.cache = JSON.parse(this.store.getItem(this.key));
      }
      return Promise.resolve(this.cache);
    }
    if (this.cache !== null) {
      return Promise.resolve(this.cache);
    }

    let resolve;
    const promise = new Promise((_resolve) => {
      resolve = _resolve;
    });
    // add callback to queue
    this.queue.push((data) => {
      this.cache = data;
      resolve(data);
    });

    this.store.getIOSData.postMessage(
      JSON.stringify({
        key: this.key,
      })
    );
    return promise;
  }

  set(data) {
    // update cache
    this.cache = data;
    if (!isIOS) {
      return this.store.setItem(this.key, JSON.stringify(data));
    }
    this.store.setIOSData.postMessage(
      JSON.stringify({
        key: this.key,
        data,
      })
    );
  }
}

// Each store will be added to the index
Store.index = {};
// Maps the keys in storage to the instance keys
// all the keys in storage use the keyPrefix
Store.storageIndex = {};

// FIFO queue for store callbacks
Store.prototype.queue = [];
// This is the key prefix
Store.prototype.key = keyPrefix;

import axios from 'axios';
import ErrorStackParser from 'error-stack-parser';

const api = '/api/v3/projects';

class RequestContext {
  constructor(environment) {
    this.language = 'JavaScript';
    this.rootDirectory = location.origin;
    this.environment = environment;
    this.userAgent = window.navigator.userAgent;
    this.severity = 'error';
    this.notifier = { name: 'ReactLogger' };
  }

  get() {
    return { ...this, url: location.href };
  }
}

function getBacktrace(err) {
  const stackFrames = ErrorStackParser.parse(err);
  return stackFrames.map((frame) => {
    return {
      file: frame.fileName,
      function: frame.functionName,
      line: frame.lineNumber,
      column: frame.columnNumber,
    };
  });
}

function getPromiseData(reason) {
  if (!reason.isAxiosError) {
    return reason;
  }
  const { url, method, headers, baseURL, data } = reason.config;
  return {
    url,
    method,
    headers,
    baseURL,
    data,
    responseText: reason.response ? reason.response.data : '',
    status: reason.response ? reason.response.status : '',
    statusText: reason.response ? reason.response.statusText : '',
  };
}

class ErrorData {
  constructor(err) {
    this.type = err.name;
    this.message = err.message;
    this.backtrace = getBacktrace(err);
  }
  static create(err) {
    return new ErrorData(err);
  }
}

export default class Logger {
  constructor(options) {
    const params = ['projectId', 'projectKey', 'environment', 'host'];
    params.forEach((param) => {
      if (!options[param]) {
        throw new Error(`Logger expects options to the "${param}" property`);
      }
    });
    this.options = options;
    this.requestContext = new RequestContext(options.environment);
  }

  async log(data) {
    const errors = [];
    let params = {};
    if (data instanceof PromiseRejectionEvent) {
      const promiseData = getPromiseData(data.reason);
      const { isAxiosError } = data.reason;
      data = {
        promise: true,
        path: location.pathname,
        reason: isAxiosError ? undefined : data.reason,
        xhr: isAxiosError,
      };
      if (isAxiosError) {
        data.status = promiseData.status;
        data.url = promiseData.url;
        params = promiseData;
      }
    }
    if (data instanceof Error || data instanceof ErrorEvent) {
      data = data instanceof ErrorEvent ? data.error : data;
      let errData = ErrorData.create(data);
      errors.push(errData);
    } else if (typeof data === 'object' && typeof data !== null) {
      errors.push(
        ErrorData.create({
          name: 'data',
          message: JSON.stringify(data),
          stack: new Error().stack,
        })
      );
    } else {
      errors.push(
        ErrorData.create({
          name: 'text',
          message: data,
          stack: new Error().stack,
        })
      );
    }

    if (this.options.environment === 'development') {
      return console.warn(data);
    }

    const { projectId, projectKey, host } = this.options;
    return axios({
      headers: {
        accept: '*/*',
        'content-type': 'text/plain;charset=UTF-8',
      },
      method: 'post',
      url: `${host}${api}/${projectId}/notices?key=${projectKey}`,
      data: {
        context: this.requestContext.get(),
        environment: {},
        errors,
        params,
        session: {},
      },
    }).catch((err) => {
      // TODO save errors in storage until back online
      console.warn(err);
    });
  }
}

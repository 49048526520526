import Store from './store';
import { contextProgression, pluralMap, types } from './config';
import { findParentIdField } from './sync';

export default class FakeResponse {
  constructor(req) {
    // this is not enumerable
    Object.defineProperty(this, 'request', {
      value: req,
    });
    // mock an axios response
    this.status = 200;
    this.data = {
      success: 'ok',
      data: {
        id: null,
        type: null,
        relationships: {},
        attributes: {},
      },
    };
  }

  static async create(req) {
    let fake = new FakeResponse(req);
    await fake.fill();
    return fake;
  }

  get targetData() {
    return this.data.data;
  }

  get relationships() {
    return this.data.data.relationships;
  }

  get attributes() {
    return this.data.data.attributes;
  }

  setType(parentType) {
    const parentContextIndex = contextProgression.indexOf(parentType);
    this.targetData.type = contextProgression[parentContextIndex + 1];
  }

  async setRelationships(parentType, parentId) {
    this.relationships[parentType] = {
      data: {
        id: parentId,
        type: parentType,
      },
    };
    // level needs scope of the property relationship
    if (this.targetData.type === types.level) {
      const buildings = await Store.get(pluralMap[types.building]);
      const building = buildings.find((building) => building.id === parentId);
      this.relationships.property = {
        data: {
          id: building.relationships.property.data.id,
        },
      };
    }
  }

  async fill() {
    let parentIdField = findParentIdField(this.request.url);
    const parentType = parentIdField.replace(/_id$/, '');
    const parentId = this.request.data[parentIdField];

    this.setType(parentType);

    Object.keys(this.request.data).forEach((key) => {
      if (key === 'authenticity_token') {
        return;
      }
      if (key === 'image') {
        return;
      }
      this.attributes[key] = this.request.data[key];
    });

    this.targetData.id = this.request.data.id;

    if (this.request.method === 'delete') {
      return (this.attributes.deleted_at = Date.now());
    } else if (this.targetData.type === types.space) {
      // get spaceTypes with space_type_id
      const spaceTypeId = this.attributes.space_type_id;
      const items = await Store.get('spaceTypes');
      const inspection = await Store.get('inspection');
      const spaceType = items.find((item) => item.id === spaceTypeId);
      this.attributes.space_type = {
        id: spaceType.id,
        name: spaceType.attributes.name,
      };

      // Spaces created offline should always be part of current inspection
      this.attributes['inspection_ids'] = [parseInt(inspection.id)];

      delete this.attributes.space_type_id;
    } else if (this.targetData.type === types.score) {
      const isImage = !!this.request.data.image;
      const spaceId = (this.attributes.space_id = Number(
        this.attributes.space_id
      ));
      let imageObj;
      if (isImage) {
        imageObj = {
          path: this.request.data.image,
          name: '',
          offline: true,
        };
      }
      // if new score, add image_urls else update them
      // if offline, image_url object will have property "offline" == true
      if (this.request.method === 'post') {
        // new score, add image_url
        this.attributes.image_urls = {};
      } else {
        const scores = await Store.get(pluralMap[types.score]);
        const score = scores.find(
          (score) =>
            score.attributes.space_id === spaceId &&
            score.attributes.issue_id === this.attributes.issue_id
        );
        this.attributes.image_urls = score ? score.attributes.image_urls : {};
      }
      if (isImage) {
        this.attributes.image_urls[Date.now()] = imageObj;
      }
    }

    return this.setRelationships(parentType, parentId);
  }
}

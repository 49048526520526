import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';

const editItems = [
  'client',
  'owner',
  'college',
  'property',
  'campus',
  'location',
  'year_built',
  'count_buildings',
  'gsf',
  'residential_units',
  'no_of_beds',
];

export default class PropertyIdentification extends ReportSection {
  itemFormFieldsList() {
    return editItems.map(name => this.listItem(name)).filter(Boolean);
  }

  render() {
    return (
      <Fragment>
        {this.itemFormFieldsList()}
        {this.textarea('inspection_names')}
        {this.textarea('operations_manager')}
      </Fragment>
    );
  }
}

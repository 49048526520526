import React from 'react';
import PropTypes from 'prop-types';

export default function ReportJobNumberListItem({
  jobNumber,
  key,
  selectJobNumber,
}) {
  const clickHandler = () => selectJobNumber(jobNumber);
  return (
    <li onClick={clickHandler} key={key} className="list-component__inspection">
      <p className="list-component__name">{jobNumber}</p>
    </li>
  );
}

ReportJobNumberListItem.propTypes = {
  selectJobNumber: PropTypes.func.isRequired,
  jobNumber: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

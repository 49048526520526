import SectionType from './SectionType';

export default class Maintenance extends SectionType {
  constructor(props) {
    super(props);
    this.categoriesKey = 'issues';
  }

  get name() {
    return 'Maintenance';
  }

  componentDidUpdate(prevProps) {
    if (this.props.sectionType !== prevProps.sectionType) {
      this.receiveProps(this.props);
    }
  }

  saveState() {
    this.saveSection('issues');
  }
}

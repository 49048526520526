import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { filterScoredItems, searchItems, sortItems } from './utils';
import { types } from '../config';
import PropTypes from 'prop-types';

const ListFilterContext = React.createContext();

function ListFilterProvider({ children, items, categoryCounter, type }) {
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [showUnscoredOnly, setShowUnscoredOnly] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [baseItems, setBaseItems] = useState(items);
  const typeRef = useRef();

  useEffect(() => {
    setBaseItems(items);
  }, [items]);

  // Clear out when there is a new level.
  // Do not clear out if going to score context or space.
  useEffect(() => {
    const redirectingToScore =
      typeRef.current === types.space && type === types.score;
    const redirectingBackToSpace =
      typeRef.current === types.score && type === types.space;

    if (!redirectingToScore && !redirectingBackToSpace) {
      setIsSortAsc(true);
      setShowUnscoredOnly(false);
      setShowFilters(false);
      setSearchTerm('');
    }

    typeRef.current = type;
  }, [type]);

  // This will filter and sort on all actions every time for simplicity sake.
  // We can revisit if there are thousands of objects that need to be sorted.
  const getFilteredItems = useCallback(() => {
    // This conditional is a hack because we are still relying on baseItems.
    // We need to remove baseItems altogether.
    if (!baseItems[0] || !baseItems[0].type) {
      return items;
    }

    const scopedItems = searchItems({ searchTerm, items: baseItems });
    const filteredScoredItems = filterScoredItems({
      showUnscoredOnly,
      categoryCounter,
      items: scopedItems,
    });

    return sortItems({ isSortAsc, items: filteredScoredItems });
  }, [
    categoryCounter,
    searchTerm,
    showUnscoredOnly,
    showFilters,
    setShowFilters,
    isSortAsc,
    baseItems,
    items,
  ]);

  const value = useMemo(() => {
    return {
      isSortAsc,
      setSearchTerm,
      searchTerm,
      showFilters,
      setShowFilters,
      setIsSortAsc,
      filteredItems: type !== types.score ? getFilteredItems() : [],
      showUnscoredOnly,
      setShowUnscoredOnly,
      setBaseItems,
    };
  }, [
    isSortAsc,
    setSearchTerm,
    searchTerm,
    setIsSortAsc,
    showUnscoredOnly,
    setShowUnscoredOnly,
    getFilteredItems,
    type,
    setBaseItems,
  ]);

  return (
    <ListFilterContext.Provider value={value}>
      {children}
    </ListFilterContext.Provider>
  );
}

function useListFilter() {
  const context = React.useContext(ListFilterContext);
  if (context === undefined) {
    throw new Error('useListFilter must be used within a ListFilterContext');
  }
  return context;
}

export { ListFilterProvider, useListFilter };

ListFilterProvider.propTypes = {
  children: PropTypes.element,
  categoryCounter: PropTypes.object,
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
};

import React, { Fragment } from 'react';
import update from 'immutability-helper';
import App from '../../containers/App';
import ReportSection from '../../containers/ReportSection';
import ReportCategoryListItem from '../ReportCategoryListItem';
import ReportItemInfo from '../ReportItemInfo';
import CustodialSpaceTypeItem from '../CustodialSpaceTypeItem';
import PhotoGallery from '../../containers/PhotoGallery';
import Loading from '../Loading';
import { axios, api } from 'config';

const defaultState = {
  category_code: null,
  issue_category_id: null,
  categoryIndex: 0,
  spaceTypeIndex: 0,
  issues: [],
  photos: [],
};

export default class SectionType extends ReportSection {
  constructor(props) {
    super(props);
    this.state = { ...defaultState, ...props.content };
    this.isCustodial = this.name === 'Custodial';
    this.getPhotos();
  }

  receiveProps(props) {
    this.setState({ ...defaultState, ...props.content }, () =>
      this.getPhotos()
    );
  }

  getPhotos() {
    const endpoint = `${api.inspections}/${this.props.currentInspectionId}/photos`;
    return axios.get(endpoint).then((res) => {
      const { data } = res;
      this.setState({ photos: data });
    });
  }

  saveState() {
    throw new Error('Should be implemented in child');
  }

  changeContent(changes, done, isScoreOverride = false) {
    const { isCustodial } = this;
    const { category, spaceType } = this.getCategory();
    const categories = [...this.state[this.categoriesKey]];
    let changeObj = { [this.state.categoryIndex]: {} };
    let scope = changeObj[this.state.categoryIndex];
    const spaceTypeIndex = isCustodial
      ? category.space_types.indexOf(spaceType)
      : undefined;

    if (isCustodial && !isScoreOverride) {
      scope.space_types = { [spaceTypeIndex]: {} };
      scope = scope.space_types[spaceTypeIndex];
    }

    // update the changeObj at the scopes level
    Object.assign(scope, changes);

    const updateState = update(categories, changeObj);

    const nextState = {
      [this.categoriesKey]: updateState,
    };

    this.setState(nextState, done);
  }

  updateDescription({ el, needsConfirm, confirmed }) {
    this.changeContent(
      Object.assign(
        {
          description: {
            $set: el.value
          }
        },
        this.pushQcHistory(confirmed)
      ),
      () => {
        if (needsConfirm) {
          this.confirmDescription({ qc: false });
        }
        this.autosizeTextarea(el);
      }
    );
  }

  updateScoreOverride({ el }, hasOriginalScore, score) {
    const content = hasOriginalScore
      ? {
          score_override: {
            $set: el,
          },
        }
      : {
          score_override: {
            $set: el,
          },
          calculated_score: {
            $set: score,
          },
        };

    this.changeContent(
      content,
      () => {
        this.saveState();
      },
      true
    );
  }

  confirmDescription({ qc }) {
    let confirmed = {};
    if (qc) {
      confirmed = {
        qc: {
          $push: [
            {
              modified_on: new Date(),
              modified_by: App.username,
              type: 'confirm',
            }
          ],
        }
      };
    } else {
      confirmed = {
        carryover_content: {
          $set: true,
        },
      };
    }

    this.changeContent({ confirmed }, () => {
      this.saveState();
    });
  }

  updatePhotoOrder(dragIndex, hoverIndex, save) {
    const { isCustodial } = this;
    const { category, spaceType } = this.getCategory();
    const dragItem = (isCustodial ? spaceType : category).photo_comments[
      dragIndex
    ];
    this.changeContent(
      {
        photo_comments: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        },
      },
      () => {
        if (save) {
          this.saveState();
        }
      }
    );
  }

  updatePhotoComment(elem, index, needsConfirm, confirmed) {
    const { value } = elem;
    this.changeContent(
      {
        photo_comments: {
          [index]: Object.assign({
            comment: {
              $set: value,
            },
          },
          this.pushQcHistory(confirmed)),
        },
      },
      () => {
        if (needsConfirm) {
          this.confirmPhotoComment({ index });
        }
        this.autosizeTextarea(elem);
      }
    );
  }

  confirmPhotoComment({ index, qc }) {
    let content = {};
    if (qc) {
      content = {
        [index]: {
          confirmed: {
            qc: {
              $push: [
                {
                  modified_on: new Date(),
                  modified_by: App.username,
                  type: 'confirm',
                }
              ],
            }
          },
        },
      };
    } else {
      content = {
        [index]: {
          confirmed: {
            carryover_content: {
              $set: true,
            },
          },
        },
      };
    }

    this.changeContent({ photo_comments: content }, () => {
      this.saveState();
    });
  }

  updatePhotoCommentImage(image, index) {
    this.changeContent(
      {
        photo_comments: {
          [index]: {
            space_name: {
              $set: image.meta.space_name,
            },
            image_path: {
              $set: image.image_path,
            },
            thumbnail_path: {
              $set: image.thumbnail_path,
            },
          },
        },
      },
      () => {
        this.saveState();
      }
    );
  }

  removePhotoComment(index) {
    this.changeContent(
      {
        photo_comments: {
          $splice: [[index, 1]],
        },
      },
      () => {
        this.saveState();
      }
    );
  }

  selectImage(image) {
    this.changeContent(
      {
        photo_comments: {
          $push: [
            {
              id: this.findNextImageId(),
              author: App.username,
              comment: '',
              space_name: image.meta.space_name,
              image_path: image.image_path,
              thumbnail_path: image.thumbnail_path,
              confirmed: {
                qc: [],
              },
            },
          ],
        },
      },
      () => {
        this.saveState();
      }
    );
  }

  addPageBreak() {
    this.changeContent(
      {
        photo_comments: {
          $push: [
            {
              id: this.findNextImageId(),
              page_break: true,
            },
          ],
        },
      },
      () => {
        this.saveState();
      }
    );
  }

  /**
   * We need to assign a unique id to each photo to track them
   * properly with DnD. Iterate through each photo and find the next
   * sequence.
   */
  findNextImageId() {
    const { isCustodial } = this;
    const { spaceType, category } = this.getCategory();
    const comments = (isCustodial ? spaceType : category).photo_comments;

    if (!comments || comments.length === 0) {
      return 0;
    }
    // find the greatest id
    const currentSequence = comments.reduce(
      (acc, value) => (value.id > acc ? value.id : acc),
      0
    );
    return currentSequence + 1;
  }

  selectCategory(index) {
    this.setState({ categoryIndex: index });
  }

  getCategory() {
    const { isCustodial } = this;
    const category = this.state[this.categoriesKey][this.state.categoryIndex];
    const spaceType =
      isCustodial && category.space_types[this.state.spaceTypeIndex];
    this.resizeTextareaItems();
    return { spaceType, category };
  }

  previewPhoto(path) {
    this.setState({ preview: path });
  }

  renderPreview() {
    return (
      <div
        id="preview-modal-wrap"
        onClick={() => this.setState({ preview: null })}
      >
        <div id="preview-modal">
          <img id="preview-modal-img" src={this.state.preview} />
          <span>Click Anywhere to Close</span>
        </div>
      </div>
    );
  }

  onKeyDown(index, e) {
    if (e.key === 'Tab') {
      const categories = this.props.content[this.categoriesKey];

      if (e.shiftKey) {
        if (index === 0) {
          return;
        }

        this.setState({ categoryIndex: index - 1 });
      } else {
        if (index === categories.length - 1) {
          return;
        }

        this.setState({ categoryIndex: index + 1 });
      }
    }
  }

  render() {
    const categories = this.props.content[this.categoriesKey];
    const { category, spaceType } = this.getCategory();
    const { isCustodial } = this;

    if (!categories.length || (isCustodial && !spaceType)) {
      return <Loading display="Getting Section" fullPage={false} />;
    }
    const className = this.name.toLowerCase();
    const categoryTypes = categories.map((category) => category.name);
    const selectedItem = isCustodial ? spaceType : category;
    let custodialSpaceTypeList = null;
    if (isCustodial) {
      const spaceTypes = category.space_types.map(
        (spaceType) => spaceType.name
      );

      custodialSpaceTypeList = (
        <div id="custodial-space-type-list" className="report-sections">
          {spaceTypes.map((type, index) => (
            <CustodialSpaceTypeItem
              type={type}
              key={index}
              selectSpaceType={() => this.selectSpaceType(index)}
              currentSpaceType={spaceType.name}
            />
          ))}
        </div>
      );
    }
    let previewModal = null;
    if (this.state.preview) {
      previewModal = this.renderPreview();
    }
    const titleClass = [
      'flex-row',
      isCustodial ? 'title-section' : 'non-custodial',
    ];

    return (
      <Fragment>
        <div style={{ display: 'flex' }}>
          <div className={titleClass.join(' ')}>
            <h4 className="custodial-text">
              Select {isCustodial ? 'a Category' : 'an Issue'}
            </h4>

            <div className="flex-column">
              <div className="flex-row">
                <h5 className="adjusted-score">Adjusted Score</h5>
                <h5 className="actual-score">Raw Score</h5>
              </div>
            </div>
          </div>
        </div>

        <div id="custodial-list-wrap">
          <div
            id="custodial-category-list"
            className={`report-sections ${className}`}
          >
            {categoryTypes.map((type, index) => (
              <ReportCategoryListItem
                type={type}
                key={type}
                score={categories[index].score}
                selectCategory={() => this.selectCategory(index)}
                currentCategory={category.name}
                scoreOverride={categories[index].score_override}
                originalScore={categories[index].calculated_score}
                saveState={() => this.saveState()}
                onKeyDown={(e) => this.onKeyDown(index, e)}
                updateScoreOverride={({ el }) =>
                  this.updateScoreOverride(
                    { el },
                    !!categories[index].calculated_score,
                    categories[index].score
                  )
                }
              />
            ))}
          </div>
          {custodialSpaceTypeList}
        </div>
        <ReportItemInfo
          moveItem={(dragIndex, hoverIndex, save) =>
            this.updatePhotoOrder(dragIndex, hoverIndex, save)
          }
          updateDescription={({ el, needsConfirm}) =>
            this.updateDescription({ el, needsConfirm, confirmed: selectedItem.confirmed})
          }
          saveState={() => this.saveState()}
          photos={this.state.photos}
          description={selectedItem.description || ''}
          previewPhoto={(path) => this.previewPhoto(path)}
          comments={selectedItem.photo_comments || []}
          removePhotoComment={(index) => this.removePhotoComment(index)}
          updatePhotoComment={(elem, index, needsConfirm, confirmed) =>
            this.updatePhotoComment(elem, index, needsConfirm, confirmed)
          }
          updatePhotoCommentImage={(image, index) =>
            this.updatePhotoCommentImage(image, index)
          }
          confirmPhotoComment={({ index, qc }) =>
            this.confirmPhotoComment({ index, qc })
          }
          confirmDescription={({ qc }) => this.confirmDescription({ qc })}
          descriptionConfirmData={selectedItem.confirmed}
        >
          <div id="section-actions">
            <PhotoGallery
              photos={this.state.photos}
              selectImage={(photo) => this.selectImage(photo)}
              buttonText="Photo Gallery"
            />
            <div
              id="page-break"
              className="btn btn--secondary"
              onClick={() => this.addPageBreak()}
            >
              <span className="fa fa-files-o"></span> Add Page Break
            </div>
          </div>
        </ReportItemInfo>
        {previewModal}
      </Fragment>
    );
  }
}

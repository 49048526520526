import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { env, isIOS } from '../config';
import logo from '../../assets/images/logo_standard.png';
import iconOnline from '../../assets/images/icon_online.svg';
import iconOffline from '../../assets/images/icon_offline.svg';

function logout(username) {
  return (
    <div id="nav-user">
      <div id="nav-user-text">
        You are logged in as
        <div id="nav-username">{username}</div>
      </div>
      <Link to="/logout" id="nav-logout" className="nav-link">
        Logout
      </Link>
    </div>
  );
}

function getOnlineState(isOnline) {
  const reload = (e) => {
    e.preventDefault();
    window.location.reload();
  };
  const icon = isOnline ? iconOnline : iconOffline;
  const onlineState = isOnline ? 'online' : 'offline';
  return (
    <a
      href="#"
      id="nav-online-state"
      className={`nav-link ${onlineState}`}
      onClick={reload}
    >
      <img id="nav-online-state-icon" src={icon} />
      {onlineState}
    </a>
  );
}

function getStagingHeader() {
  return (
    <div id="staging-header">
      This is the Testing Environment — do not use for actual inspections!
    </div>
  );
}

function getAuthHeader(isOnline, username, userIsAdmin, location, highContrastToggle) {
  const hasActiveClass = (path) => {
    if (location.indexOf(path) >= 0) {
      return 'active';
    }
  };

  return (
    <div id="nav-wrap">
      <div id="nav-logo">
        <img id="nav-logo-img" src={logo} />
      </div>
      {getOnlineState(isOnline)}
      <div id="nav-links">
        {isIOS || (
          <Fragment>
            <Link className={`nav-link ${hasActiveClass('/view')}`} to="/">
              Scores
            </Link>
            <Link
              className={`nav-link ${hasActiveClass('/reports')}`}
              to="/reports"
            >
              Reports
            </Link>
            {userIsAdmin && (
              <a className="nav-link" href="/admin">
                Admin
              </a>
            )}
          </Fragment>
        )}
        {isIOS && highContrastToggle}
      </div>
      {username && logout(username)}
    </div>
  );
}

export default function Header(props) {
  const [isHighContrast, setIsHighContrast] = useState(
    document.body.classList.contains('high-contrast')
  );

  const highContrastToggle = () => {
    const handleToggleClick = () => {
      if (isHighContrast) {
        document.body.classList.remove('high-contrast');
        setIsHighContrast(false);
      } else {
        document.body.classList.add('high-contrast');
        setIsHighContrast(true);
      }
    };

    return (
      <div className="toggle-container">
        <span>High Contrast</span>
        <input
          className="toggle-switch toggle-switch-on-off"
          type="checkbox"
          id="contrast-toggle"
          checked={isHighContrast}
          onChange={handleToggleClick}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <nav id="navbar">
        {props.authorized
          ? getAuthHeader(
              props.isOnline,
              props.username,
              props.userIsAdmin,
              props.location,
              highContrastToggle()
            )
          : getOnlineState(props.isOnline)}
      </nav>
      {env === 'staging' && getStagingHeader()}
    </Fragment>
  );
}

Header.propTypes = {
  username: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  history: PropTypes.object,
  authorized: PropTypes.bool,
  location: PropTypes.string,
};

import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';

export default class UnresolvedItems extends ReportSection {
  render() {
    const { content } = this.props;
    const currentList = () => {
      if (content.bullet_list) {
        return content.bullet_list.map((props, index) =>
          this.bullet(props, index)
        );
      } else {
        return [];
      }
    };
    return (
      <Fragment>
        <div className="report-content-padding">
          <input
            id="no-unresolved-items-value"
            onChange={(e) => {
              this.updateState('no_unresolved_items', e.target, () => {
                this.saveSection('no_unresolved_items');
              });
            }}
            type="checkbox"
            checked={content.no_unresolved_items}
          />
          <label htmlFor="no-unresolved-items-value">No Unresolved Items</label>
        </div>
        <div id="bullet-list-info" className="report-content-padding">
          <p>
            During previous APPA evaluations, Marx|Okubo identified items that
            require special attention in order to meeting and/or maintain a
            target APPA rating level of 2.0
          </p>
          <p>
            As part of the current evaluation, items that we noted during
            previous survey(s), but not yet resolved, have been specifically
            identified. A brief summary of these unresolved items follows.
          </p>
          <p>
            Note that minor items considered to be part of normal building
            operations, such as scuffed floors or walls are not included in this
            list unless they are considered to be widespread and/or systemic of
            a broader issue. In addition, we have not included major items that
            would be considered design or construction flaws that could not be
            remedied without major capital improvements to the facility.
          </p>
        </div>
        <div className="report-content-padding" id="bullet-list">
          {currentList()}
          {this.bullet(
            { resolved: '', value: '', confirmed: {} },
            currentList().length,
            true
          )}
        </div>
      </Fragment>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import FormSelectGroup from '../FormSelectGroup';
import FormActions from '../FormActions';
import { ensureAllValid } from '../../utils';

export default function SpaceForm({
  action,
  state,
  spaceTypes,
  levels,
  isUnique,
  isValid,
  isDisabled,
  updateValue,
  send,
  cancel,
  destroy,
}) {
  return (
    <form className="create" id="create-space-form">
      <FormGroup
        isUnique={isUnique}
        isValid={isValid.name}
        setFocus
        label="Name"
        name="name"
        state={state.name}
        update={updateValue}
        setRequired
      />
      <FormSelectGroup
        isValid={isValid.space_type_id}
        label="Space Type"
        name="space_type_id"
        options={spaceTypes}
        state={state.space_type_id}
        update={updateValue}
        setRequired
      />
      <FormSelectGroup
        isValid={isValid.level_id}
        label="Level"
        name="level_id"
        options={levels}
        state={state.level_id}
        update={updateValue}
        setRequired
      />
      <FormGroup
        label="Description"
        name="description"
        state={state.description}
        update={updateValue}
      />
      <FormActions
        action={action}
        disableSend={isDisabled || !ensureAllValid(isValid)}
        send={send}
        cancel={cancel}
        destroy={destroy}
        type="space"
      />
    </form>
  );
}

SpaceForm.propTypes = {
  action: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  isUnique: PropTypes.bool.isRequired,
  isValid: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  spaceTypes: PropTypes.array.isRequired,
  levels: PropTypes.array.isRequired,
  updateValue: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  destroy: PropTypes.func,
};

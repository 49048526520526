import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function getEditLink(editPath) {
  if (!editPath) {
    return null;
  }
  return (
    <Link to={editPath} className="breadcrumb-edit-link">
      Edit
    </Link>
  );
}

function getCurrentCrumb(breadcrumbs, editPath) {
  if (!breadcrumbs.length) {
    return null;
  }

  const editRoute = breadcrumbs.slice(-1)[0];

  return (
    <div className="breadcrumb-component__crumb title-crumb">
      <h1>{decodeURIComponent(editRoute)}</h1>
      {editPath ? getEditLink(editPath) : null}
    </div>
  );
}

export default function Breadcrumb({
  routeChain = ['/view'],
  routeParts,
  editFormPath,
  propertyName = '',
}) {
  const params = useParams();

  const size = routeParts.length;
  const crumbs = routeParts.slice(0, size - 1);
  const isReportPage = routeChain[0] === '/reports';

  const isInspectionNumber = (route) =>
    isReportPage && params.inspectionId && route === params.inspectionId;
  const defaultRoute = isReportPage ? '/reports' : '/';

  const links = crumbs.map((route, key) => {
    routeChain.push(route);

    return (
      <Link
        key={key}
        to={routeChain.join('/')}
        className="breadcrumb-component__crumb"
      >
        {decodeURIComponent(isInspectionNumber(route) ? propertyName : route)}
      </Link>
    );
  });

  return (
    <div className="breadcrumb-component-container">
      <div className="breadcrumb-component">
        <Link to={defaultRoute} className="breadcrumb-component__crumb">
          Colleges
        </Link>
        {links}
        {getCurrentCrumb(routeParts, editFormPath)}
      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  routeChain: PropTypes.array,
  routeParts: PropTypes.array.isRequired,
  editFormPath: PropTypes.string,
  propertyName: PropTypes.string,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { api, auth, logger } from '../config';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loading from '../components/Loading';
import ReportList from '../components/ReportList';
import ReportSectionListItem from '../components/ReportSectionListItem';
import FrontPage from '../components/report-sections/FrontPage';
import GeneralInfo from '../components/report-sections/GeneralInfo';
import TableOfContents from '../components/report-sections/TableOfContents';
import PropertyIdentification from '../components/report-sections/PropertyIdentification';
import FacilityDashboard from '../components/report-sections/FacilityDashboard';
import ObservationInformation from '../components/report-sections/ObservationInformation';
import UnresolvedItems from '../components/report-sections/UnresolvedItems';
import EvaluationMethodology from '../components/report-sections/EvaluationMethodology';
import Maintenance from '../components/report-sections/Maintenance';
import Custodial from '../components/report-sections/Custodial';
import Breadcrumb from '../components/Breadcrumb';

const axios = Axios.create({
  baseURL: api.baseUrl,
  timeout: 60000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

const sectionComponents = {
  'General Info': GeneralInfo,
  'Front Page': FrontPage,
  'Table Of Contents': TableOfContents,
  'Property Identification': PropertyIdentification,
  'Facility Appa Level Dashboard': FacilityDashboard,
  'Observation Information': ObservationInformation,
  'Unresolved Items': UnresolvedItems,
  'Evaluation Methodology': EvaluationMethodology,
  'Building Systems Reliability': Maintenance,
  'Exterior Aesthetics': Maintenance,
  'Maintenance Mix': Maintenance,
  'Interior Aesthetics': Maintenance,
  'Regulatory Compliance': Maintenance,
  'Service Efficiency': Maintenance,
  'Custodial': Custodial,
};

const sectionRouteNames = Object.keys(sectionComponents);

const reportFinalized = (status) => {
  return ['final', 'revised final'].includes(status);
};

const defaultState = {
  inspections: [],
  sections: [],
  sectionType: '',
  ready: false,
};

/**
 * Helper method to modify the axios requests and add CSRF token
 */
function interceptorAttachCSRF(config) {
  if (config.data && typeof config.data !== 'string') {
    config.data.authenticity_token = auth.token;
  }
  return config;
}

function getRouteParts(params) {
  return Object.keys(params).map((param) => params[param]);
}

export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.interceptor = null;
    this.state = defaultState;
    this.initialSection = 'General Info';
  }

  componentDidMount() {
    this.interceptor = axios.interceptors.request.use(interceptorAttachCSRF);
    this.getInspections();
  }

  componentWillUnmount() {
    axios.interceptors.request.eject(this.interceptor);
    this.interceptor = null;
  }

  componentDidUpdate(prevProps) {
    if (this.state.bypass) {
      return;
    }

    const { inspectionId, sectionType, jobNumber, collegeName } =
      this.props.match.params;
    const path = this.props.location.pathname;

    // redirect to default section if inspection is selected
    if (inspectionId) {
      if (!sectionType) {
        return this.props.history.replace(
          `/reports/${collegeName}/${jobNumber}/${inspectionId}/${this.initialSection}`
        );
      }
      // update the inspection
      if (
        !this.state.inspection ||
        prevProps.match.params.inspectionId !== inspectionId
      ) {
        return this.getReportSections();
      }
      // update the section
      if (prevProps.match.params.sectionType !== sectionType) {
        return this.setState(this.getCurrentSection());
      }
    } else if (prevProps.location.pathname !== path) {
      if (path === '/reports') {
        // reset the state
        this.setState(defaultState);
        this.getInspections();
      } else if (path.indexOf('/reports') >= 0) {
        // clear section selection to show inspection list
        this.setState({ currentSection: null });
      }
    }
  }

  async getInspections() {
    const { data } = await axios.get(api.inspections);
    const inspections = data.data;

    this.setState({
      inspections,
      inspection: null,
      sections: null,
      // only set ready if we don't need to get inspection
      ready: !this.props.match.params.inspectionId,
    });
  }

  getCurrentSection(sections) {
    const sectionType = this.props.match.params.sectionType;
    const currentSection = this.getSection(sectionType, sections);
    return {
      sectionType,
      currentSection,
    };
  }

  getSection(sectionType, sections) {
    sections = sections || this.state.sections;
    const section = sections.find(
      (section) =>
        section.attributes.section_name === sectionType
    );
    return section;
  }

  getReportStatus() {
    const generalInfo = this.getSection('General Info');
    return generalInfo.attributes.form_data.status;
  }

  getCoverPhoto() {
    const frontPage = this.getSection('Front Page');
    return frontPage.attributes.form_data.cover_photo;
  }

  async getReportSections() {
    this.setState({ ready: false, bypass: true });
    const { inspectionId } = this.props.match.params;
    const url = `${api.inspections}/${inspectionId}/report_sections`;
    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        const { sectionType, currentSection } = this.getCurrentSection(data);
        this.setState({
          ready: true,
          bypass: false,
          inspection: this.state.inspections.find(
            (inspection) => inspection.id === inspectionId
          ),
          sections: data.sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1)),
          sectionType,
          currentSection,
        });
      })
      .catch((err) => {
        logger.log(err);
        this.props.history.replace('/reports');
      });
  }

  /**
   * OnClick handler bound to each list item that triggers context change
   */
  selectInspection(inspectionId) {
    const { jobNumber, collegeName } = this.props.match.params;

    this.props.history.push(
      `/reports/${collegeName}/${jobNumber}/${inspectionId}/${this.initialSection}`
    );
  }

  selectCollege(collegeName) {
    this.props.history.push(`/reports/${collegeName}`);
  }

  selectJobNumber(jobNumber) {
    const { collegeName } = this.props.match.params;

    this.props.history.push(`/reports/${collegeName}/${jobNumber}`);
  }

  selectSection(section) {
    const { jobNumber, collegeName, inspectionId } = this.props.match.params;

    const sectionType = section.attributes.section_name;
    const sectionComponent = sectionComponents[sectionType];
    if (undefined === sectionComponent) {
      throw new Error(`Section Route does not exist for ${sectionType}`);
    }
    this.props.history.push(
      `/reports/${collegeName}/${jobNumber}/${inspectionId}/${sectionType}`
    );
  }

  updateSections(updatedSection, sectionId, callback) {
    const { state } = this;
    const { currentSection } = state;
    const sectionIndex = state.sections.findIndex(
      (section) => section.id === sectionId
    );
    const sections = update(state.sections, {
      [sectionIndex]: {
        attributes: {
          form_data: {
            $set: updatedSection,
          },
        },
      },
    });
    // only update state if this is the currentSection;
    // if user has clicked into a new section, this will not occur
    const currentSectionIndex = state.sections.findIndex(
      (section) => section.id === currentSection.id
    );
    if (sectionIndex === currentSectionIndex) {
      this.setState(
        {
          currentSection: sections[currentSectionIndex],
          sections,
        },
        callback
      );
    }
  }

  async validateReport(inspectionId) {
    let validations = {
      isValid: true,
      messages: [],
    };

    if (!this.hasCoverPhoto()) {
      validations.isValid = false;
      validations.messages.push(
        'Report cannot be complete without a cover photo. Please go to the Front Page section and select a cover photo.'
      );
    }

    await axios({
      method: 'get',
      url: `${api.inspections}/${inspectionId}/validate_report`,
    }).then((res) => {
      if (Object.keys(res.data.qc).length > 0) {
        validations.isValid = false;
        validations.messages.push('Some fields still require QC confirmation.');
        validations.data = res.data.qc;
      }
    });

    return validations;
  }

  hasCoverPhoto() {
    const coverPhoto = this.getCoverPhoto();

    if (coverPhoto && coverPhoto.image_path) {
      return true;
    }

    return false;
  }

  render() {
    if (!this.state.ready) {
      return <Loading display="Getting Inspection" />;
    }

    const routeParts = getRouteParts(this.props.match.params);
    // this.state.currentSection is being used to determine whether we should render a whole different page
    // this should be removed/refactored
    if (this.state.currentSection && routeParts.length > 3) {
      const { inspection, currentSection, sections, sectionType } = this.state;

      const reportStatus = this.getReportStatus();
      const sectionsList = sections.map((section, index) => (
        <ReportSectionListItem
          id={section.id}
          name={section.attributes.section_name}
          currentItem={currentSection.id}
          selectItem={() => this.selectSection(section)}
          reportFinalized={reportFinalized(reportStatus)}
          key={index}
        />
      ));
      const SectionComponent = sectionComponents[sectionType];
      if (!SectionComponent) {
        return <h3>This has not been configured yet!</h3>;
      }
      const previewSectionBtn = () => {
        if (
          ['table_of_contents', 'general_info'].includes(
            currentSection.attributes.section_type
          )
        )
          return;

        return (
          <a
            id="view-section-pdf"
            className="btn btn--mini"
            href={`/inspections/${inspection.id}/reports/${currentSection.id}.pdf`}
            target="_blank"
            style={{ float: 'right', marginRight: '10px' }}
          >
            Preview Section PDF
          </a>
        );
      };
      const viewScoresBtn = () => {
        if (inspection.attributes.archived) return;

        return (
          <Link
            id="view-property"
            className="btn btn--mini"
            to={{
              pathname: `/view/${college}/${campus}/${property}`,
              search: `?inspection=${inspection.id}`,
            }}
          >
            View Scores
          </Link>
        );
      };
      const content = currentSection.attributes.form_data;
      const property = inspection.attributes.property_name;
      const college = inspection.attributes.college_name;
      const campus = inspection.attributes.campus_name;
      const reportSectionContent = (
        <div className="report-sections-content">
          <h2 className="capitalize">
            {viewScoresBtn()}
            {property}
            {previewSectionBtn()}
          </h2>
          <SectionComponent
            sectionType={sectionType}
            currentInspectionId={inspection.id}
            currentSectionId={currentSection.id}
            updateSections={this.updateSections.bind(this)}
            content={content}
            reportStatus={reportStatus}
            validateReport={() => this.validateReport(inspection.id)}
          />
        </div>
      );

      return (
        <div id="report-sections-wrap">
          <Breadcrumb
            routeParts={routeParts}
            routeChain={['/reports']}
            propertyName={
              this.state.inspection &&
              this.state.inspection.attributes.property_name
            }
          />
          <div id="report-sections-modal">
            <div id="report-sections">
              {sectionsList}
              <div id="preview-btn">
                <a
                  className="report-section"
                  href={`/inspections/${inspection.id}/reports.pdf`}
                  target="_blank"
                >
                  Preview PDF
                </a>
              </div>
            </div>
            {reportSectionContent}
          </div>
        </div>
      );
    }

    return (
      <>
        <Breadcrumb
          routeParts={routeParts}
          routeChain={['/reports']}
          propertyName={
            this.state.inspection &&
            this.state.inspection.attributes.property_name
          }
        />
        <ReportList
          inspections={this.state.inspections}
          selectInspection={(inspectionId) =>
            this.selectInspection(inspectionId)
          }
          selectJobNumber={(jobNumber) => this.selectJobNumber(jobNumber)}
          selectCollege={(college) => this.selectCollege(college)}
        />
      </>
    );
  }
}

Reports.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export { axios, sectionRouteNames, reportFinalized };

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import PhotoGalleryImage from '../components/PhotoGalleryImage';
import PhotoFilterSelect from '../components/PhotoFilterSelect';
import PhotoNotes from '../components/PhotoNotes';
import update from 'immutability-helper';

const defaultState = {
  showing: false,
  filters: {},
};

const filterMap = {
  inspector_name: 'Inspector',
  issue_category: 'Issue Category',
  issue_name: 'Issue',
  level_name: 'Level',
  space_name: 'Space',
  space_type_name: 'Space Type',
  building_name: 'Building',
};

Object.keys(filterMap).forEach((key) => (defaultState.filters[key] = ''));

export default class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.makeFilters();
    this.closeGallery = this.closeGallery.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.closeGallery);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeGallery);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.photos !== this.props.photos) {
      this.makeFilters();
    }
  }

  closeGallery(e) {
    if (!e.target.closest('.photo-modal')) {
      this.setState({ showing: false });
    }
  }

  /**
   * Iterate over photos and their meta data to generate
   * a unique list for each item in the filterMap
   */
  makeFilters() {
    const filters = {};
    Object.keys(filterMap).forEach((key) => (filters[key] = new Set()));
    this.props.photos.forEach((photo) => {
      const { meta } = photo;
      // build a hash of values
      for (let key in filters) {
        filters[key].add(meta[key]);
      }
    });

    this.filters = filters;
  }

  renderHeader() {
    return (
      <div id="photo-gallery-header" className="photo-gallery-section">
        <div
          onClick={() => this.setState({ showing: false })}
          id="photo-gallery-close"
          className="fa fa-remove"
        ></div>
      </div>
    );
  }

  updateFilters(filter, value) {
    const filters = update(this.state.filters, {
      [filter]: {
        $set: value,
      },
    });
    this.setState({ filters });
  }

  getFilter({ label, options, key }) {
    return (
      <PhotoFilterSelect
        key={key}
        label=""
        className="photo-gallery-filter"
        defaultOption={label}
        name={key}
        options={options}
        state={this.state.filters[key]}
        update={(e) => this.updateFilters(key, e.target.value)}
      />
    );
  }

  renderFilters() {
    const { filters } = this;
    const lists = Object.keys(filters).map((key) => {
      const options = Array.from(filters[key]);
      const label = filterMap[key];
      return this.getFilter({ label, options, key });
    });
    return (
      <div id="photo-gallery-filters" className="photo-gallery-section">
        Filters: {lists}
      </div>
    );
  }

  confirmPhoto(photo) {
    this.setState({
      confirm: true,
      photo,
    });
  }

  cancelSelection() {
    this.setState({
      confirm: false,
      photo: null,
    });
  }

  selectPhoto() {
    const { photo } = this.state;
    this.props.selectImage(photo);
    this.cancelSelection();
  }

  render() {
    if (!this.state.showing) {
      return (
        <div
          id="photo-gallery-button"
          className="btn btn--secondary"
          onClick={() => this.setState({ showing: true })}
        >
          <span className="fa fa-photo"></span>
          {this.props.buttonText}
        </div>
      );
    }
    const { filters } = this.state;
    const filterKeys = Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        acc.push(key);
      }
      return acc;
    }, []);
    const filterSize = filterKeys.length;
    const photos = this.props.photos.reduce((acc, photo, index) => {
      const { meta } = photo;
      let found = true;
      for (let i = 0; i < filterSize; i++) {
        const filterKey = filterKeys[i];
        const filterValue = filters[filterKey];
        // look for one non-match and break
        if (meta[filterKey] !== filterValue) {
          found = false;
          break;
        }
      }
      if (found) {
        acc.push(
          <PhotoGalleryImage
            key={index}
            thumbnailPath={photo.thumbnail_path}
            selectImage={() => this.confirmPhoto(photo)}
          />
        );
      }
      return acc;
    }, []);
    if (photos.length === 0) {
      photos.push(
        <div key="0" id="no-photos">
          No photos with selected filters
        </div>
      );
    }

    let photoPreview = (
      <div className="preview-placeholder">
        <p>Select a photo to preview</p>
      </div>
    );

    if (this.state.confirm) {
      photoPreview = (
        <div>
          <div id="photo-confirm-photo">
            <img id="photo-confirm-img" src={this.state.photo.image_path} />
            <div id="photo-confirm-notes">
              <PhotoNotes photoData={this.state.photo.meta} />
            </div>
          </div>
          { this.props.selectImage &&
            <Fragment>
              <div id="photo-confirm-text">Use this photo?</div>
              <div id="photo-confirm-wrap">
                <button
                  type="button"
                  id="confirm-yes"
                  className="photo-confirm"
                  onClick={() => this.selectPhoto()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  id="confirm-no"
                  className="photo-confirm"
                  onClick={() => this.cancelSelection()}
                >
                  No
                </button>
              </div>
            </Fragment>
          }
        </div>
      );
    }

    return (
      <div id="photo-gallery-wrap">
        <div id="photo-gallery-container">
          <div className="photo-modal" style={{textAlign: "left"}}>
            {this.renderHeader()}
            {this.renderFilters()}
            <div className="flex">
              <div className="photo-gallery">
                <div id="photo-gallery-content">{photos}</div>
              </div>
              <div className="photo-preview">{photoPreview}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PhotoGallery.propTypes = {
  photos: PropTypes.array.isRequired,
  selectImage: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  buttonText: PropTypes.string.isRequired,
};

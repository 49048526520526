import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import PhotoGallery from '../containers/PhotoGallery';
import PhotoNotes from './PhotoNotes';

export default function ReportPhoto({
  author,
  comment,
  thumbnailPath,
  previewPhoto,
  updatePhotoComment,
  updatePhotoCommentImage,
  saveState,
  photos,
  needsConfirm,
  confirmItem,
}) {
  const [noteIsVisible, showHideNote] = useState(false);
  const toggleNote = (e) => {
    e.preventDefault();
    showHideNote(!noteIsVisible);
  };
  const noteLink = (
    <a
      href="#"
      onClick={toggleNote}
      className="custodial-photo-comment-note btn btn--mini"
    >
      {noteIsVisible ? 'hide' : 'show'} notes
    </a>
  );
  const getPhotoData = () => {
    const photo = photos.find(
      ({ thumbnail_path }) => thumbnail_path === thumbnailPath
    );
    return photo.meta;
  };

  function thumbnailOrGallery() {
    if (thumbnailPath) {
      return (
        <div className="custodial-photo-wrap" onClick={previewPhoto}>
          <img src={thumbnailPath} className="custodial-photo" />
        </div>
      );
    } else {
      return (
        <PhotoGallery
          photos={photos}
          selectImage={(photo) => updatePhotoCommentImage(photo)}
          buttonText="Select Photo"
        />
      );
    }
  }

  let commentClasses = 'custodial-info-description report-item-text grow';
  if (needsConfirm) {
    commentClasses += ' unconfirmed';
  }

  return (
    <Fragment>
      {thumbnailOrGallery()}
      <div className="custodial-photo-comment">
        <div className="custodial-photo-comment-info">
          <div className="custodial-photo-comment-author">{author}</div>
          {noteLink}
        </div>
        {noteIsVisible && (
          <PhotoNotes photoData={getPhotoData()} className="photo-notes" />
        )}
        <div className="flex">
          <textarea
            className={commentClasses}
            value={comment}
            placeholder="Add a comment..."
            onChange={(e) => updatePhotoComment(e.target, needsConfirm)}
            onBlur={() => saveState()}
          />
          {comment && confirmItem}
        </div>
      </div>
    </Fragment>
  );
}

ReportPhoto.propTypes = {
  author: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  thumbnailPath: PropTypes.string,
  previewPhoto: PropTypes.func.isRequired,
  saveState: PropTypes.func.isRequired,
  updatePhotoComment: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
  needsConfirm: PropTypes.bool.isRequired,
  confirmItem: PropTypes.object.isRequired,
};

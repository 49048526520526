import React from 'react';
import PropTypes from 'prop-types';

export default function PhotoNotes({ className, photoData }) {
  const label_and_value = (label, value) => {
    return (
      <div>
        <span style={{fontWeight: 'bold'}}>{label}:</span> {value}
      </div>
    );
  };

  return (
    <div className={className}>
      { label_and_value('Space', photoData.space_name) }
      { label_and_value('Level', photoData.level_name) }
      { label_and_value('Issue', photoData.issue_name) }
      { label_and_value('Issue Category', photoData.issue_category) }
      { photoData.score_notes.length > 0 ?
        label_and_value('Notes', photoData.score_notes)
        :
        ''
      }
    </div>
  );
}

PhotoNotes.propTypes = {
  className: PropTypes.string,
  photoData: PropTypes.object.isRequired,
};

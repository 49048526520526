import React from 'react';
import { routes } from '../config';
import PropTypes from 'prop-types';

export default function FormActions({
  action,
  type,
  disableSend,
  send,
  cancel,
  destroy,
}) {
  const submitText = action === routes.create ? 'Add' : 'Save';
  const showDeleteButton = destroy && action != routes.create;

  return (
    <div className="form-group">
      <div className="form-actions">
        <button
          disabled={disableSend}
          className="btn btn--primary add capitalize"
          onClick={send}
        >
          {submitText} {type}
        </button>
        {showDeleteButton && (
          <button className="btn btn--secondary" onClick={destroy}>
            Delete {type}
          </button>
        )}
        <button className="btn btn--secondary cancel" onClick={cancel}>
          Cancel
        </button>
      </div>
    </div>
  );
}

FormActions.propTypes = {
  action: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disableSend: PropTypes.bool.isRequired,
  send: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  destroy: PropTypes.func,
};

import PropTypes from 'prop-types';
import { api, auth } from '../config';
import Axios from 'axios';

const axios = Axios.create({
  baseURL: api.baseUrl,
  timeout: 7000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

export default function Logout({ getUser }) {
  axios({
    url: api.logout,
    method: 'delete',
    data: {
      authenticity_token: auth.token,
    },
  }).then((res) => {
    // update to new auth-token
    getUser();
  });
  return null;
}

Logout.propTypes = {
  getUser: PropTypes.func.isRequired,
};

import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';

export default class ObservationInformation extends ReportSection {
  render() {
    return (
      <Fragment>
        {this.textarea('observation_introduction')}
        {this.textarea('site_observation')}
      </Fragment>
    );
  }
}

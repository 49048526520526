import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../components/Tooltip';

function confirmButton({text, clickHandler, classNames=''}) {
  return (
    <Tooltip
      content={
        <button
          className={`btn btn--mini confirm-btn ${classNames}`}
          onClick={() => clickHandler()}
        >
          ✓
        </button>
      }
      text={text}
    />
  );
}

function confirmText({entry, showHistory=null, setShowHistory=null, index=null}) {
  const date = new Date(entry.modified_on).toLocaleString('en-US');
  const actionText = entry.type === 'confirm' ? 'QC' : 'Change';

  let attrs = {};
  if (index === null) {
    attrs.onClick = () => setShowHistory(!showHistory);
    attrs.style = {cursor: 'pointer'};
  } else {
    attrs.key = index;
    attrs.style = {margin: '5px 3px'};
  }

  return (
    <div className='qc-text' {...attrs}>
      <span>{`${actionText} by ${entry.modified_by}`}</span>
      <br />
      <span>{date}</span>
    </div>
  );
}

function historyModal({confirmed, setShowHistory}) {
  const modalStyle = {
    position: 'absolute',
    width: '200px',
    right: 0,
    background: 'white',
    padding: '1rem',
    border: '1px solid black',
    zIndex: '99',
  };

  return (
    <div style={modalStyle} className="historyModal">
      <div>
        <span
          className="fa fa-remove"
          style={{float: 'right', cursor: 'pointer'}}
          onClick={() => setShowHistory(false)}
        >
        </span>
      </div>
      {confirmed.qc.reverse().map((entry, index) => confirmText({entry, index}))}
    </div>
  );
}

export default function ConfirmItem({
  confirmHandler,
  qcHandler,
  confirmed,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const lastConfirmedEntry = confirmed.qc.slice(-1)[0];
  const needsQC = !lastConfirmedEntry || lastConfirmedEntry.type === 'change';
  const needsConfirm =
    confirmed.carryover_content != undefined && !confirmed.carryover_content;
  const closeHistoryModal = (e) => {
    if (!e.target.closest('.historyModal')) {
      setShowHistory(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeHistoryModal);
    return () => {
      document.body.removeEventListener('click', closeHistoryModal);
    };
  });

  if (!needsQC && !needsConfirm) {
    return (
      <div style={{position: 'relative', width: '150px'}}>
        {confirmText({entry: lastConfirmedEntry, showHistory, setShowHistory})}
        {showHistory && historyModal({confirmed, setShowHistory})}
      </div>
    );
  }

  if (needsConfirm) {
    return confirmButton({
      text: 'Confirm text as-is',
      clickHandler: confirmHandler,
    });
  }

  if(needsQC) {
    return confirmButton({
      text: 'Finalize text QC',
      clickHandler: qcHandler,
      classNames: 'qc-btn',
    });
  }
}

ConfirmItem.propTypes = {
  confirmed: PropTypes.object.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  qcHandler: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import FormActions from '../FormActions';
import { ensureAllValid } from '../../utils';

export default function BuildingForm({
  action,
  state,
  isUnique,
  isValid,
  isDisabled,
  updateValue,
  send,
  cancel,
}) {
  return (
    <form className="create" id="create-building-form">
      <FormGroup
        isUnique={isUnique}
        isValid={isValid.name}
        setFocus
        setRequired
        label="Name"
        name="name"
        state={state.name}
        update={updateValue}
      />
      <FormGroup
        label="Street"
        name="street_address"
        state={state.street_address}
        update={updateValue}
      />
      <FormGroup
        label="City"
        name="city"
        state={state.city}
        update={updateValue}
      />
      <FormGroup
        label="State"
        name="state"
        state={state.state}
        update={updateValue}
      />
      <FormGroup
        label="Zip"
        name="zip"
        state={state.zip}
        update={updateValue}
      />
      <FormGroup
        label="Notes"
        name="notes"
        state={state.notes}
        update={updateValue}
      />
      <FormActions
        action={action}
        send={send}
        disableSend={isDisabled || !ensureAllValid(isValid)}
        cancel={cancel}
        type="building"
      />
    </form>
  );
}

BuildingForm.propTypes = {
  action: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  isUnique: PropTypes.bool.isRequired,
  isValid: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updateValue: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

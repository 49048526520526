import React from 'react';
import PropTypes from 'prop-types';

export default function ReportSectionListItem({
  id,
  name,
  currentItem,
  selectItem,
  reportFinalized,
}) {
  let className = 'report-section';
  if (currentItem === id) {
    className += ' selected';
  }
  if (reportFinalized) {
    className += ' disabled';
  }

  return (
    <div
      className={className}
      {...(!reportFinalized && { onClick: selectItem })}
    >
      {name}
      <span className="fa fa-chevron-right"></span>
    </div>
  );
}

ReportSectionListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentItem: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  reportFinalized: PropTypes.bool.isRequired,
};

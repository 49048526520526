import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../components/Tooltip';
import { validTwoDecimalPlaces } from '../utils';

const MAX_VALUE = 5;
const MIN_VALUE = 1;

export default function ReportCategoryListItem({
  type,
  score,
  currentCategory,
  selectCategory,
  updateScoreOverride,
  scoreOverride,
  originalScore,
  onKeyDown,
}) {
  const [overrideValue, setOverrideValue] = useState(scoreOverride || '');
  const [errorMessages, setErrorMessages] = useState('');

  const getErrorMessages = (value) => {
    const errors = [];

    if (value != '' && value < MIN_VALUE) {
      errors.push(`Value less than min value of ${MIN_VALUE}.`);
    }

    if (value > MAX_VALUE) {
      errors.push(`Value larger than max value of ${MAX_VALUE}.`);
    }

    if (!validTwoDecimalPlaces(value)) {
      errors.push('Value larger than two decimal places.');
    }

    return errors;
  };

  const onChange = (e) => {
    if (e.target.value !== '' && isNaN(Number(e.target.value))) {
      return;
    }

    setOverrideValue(e.target.value);
    const errors = getErrorMessages(e.target.value);

    if (errors.length) {
      setErrorMessages(errors.join(' '));
      return;
    }

    setErrorMessages('');
    updateScoreOverride({ el: e.target.value });
  };

  const rowClass = [
    'custodial-category-item',
    'report-section',
    currentCategory === type && 'selected',
  ]
    .filter((a) => a)
    .join(' ');

  const scoreClass = ['report-issue-actual', scoreOverride && 'strikethrough']
    .filter((a) => a)
    .join(' ');

  const scoreElem =
    score || originalScore ? (
      <span className="report-category-score flex-row">
        {errorMessages.length ? (
          <Tooltip
            content={<i className="fa fa-exclamation-triangle" />}
            text={errorMessages}
          />
        ) : null}
        <input
          onKeyDown={onKeyDown}
          className={`report-issue-override ${
            errorMessages.length ? 'has-error' : ''
          }`}
          type="text"
          value={overrideValue}
          onChange={onChange}
        />
        <div className={scoreClass}>{originalScore || score}</div>
      </span>
    ) : null;

  return (
    <div className={rowClass} onClick={selectCategory}>
      {type}
      {scoreElem}
      <span className="fa fa-chevron-right"></span>
    </div>
  );
}

ReportCategoryListItem.propTypes = {
  type: PropTypes.string.isRequired,
  score: PropTypes.string,
  selectCategory: PropTypes.func.isRequired,
  currentCategory: PropTypes.string.isRequired,
  updateScoreOverride: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  scoreOverride: PropTypes.string,
  originalScore: PropTypes.string,
  saveState: PropTypes.func.isRequired,
};

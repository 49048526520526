import Axios from 'axios';
import Logger from 'logger';

const env = window.APPLICATION_ENVIRONMENT;
window.showStorageSize = function () {
  var total = 0;
  Object.keys(sessionStorage).forEach((key) => {
    var amount = (sessionStorage[key].length * 2) / 1024;
    total += amount;
    console.log(key + ' = ' + amount.toFixed(2) + ' KB');
  });
  console.log('Total: ' + total.toFixed(2) + ' KB');
  console.log('Total: ' + (total / 1024).toFixed(2) + ' KB');
};

// used for ajax
const api = {
  baseUrl: location.origin,
  data: 'data',
  login: 'users/sign_in',
  logout: 'users/sign_out',
  campus: 'api/v1/campuses',
  property: 'api/v1/properties',
  building: 'api/v1/buildings',
  level: 'api/v1/levels',
  space: 'api/v1/spaces',
  score: 'api/v1/scores',
  heartbeat: 'api/v1/heartbeat',
  inspections: 'api/v1/inspections',
};

// used only by react router
const routes = {
  login: 'login',
  logout: 'logout',
  view: 'view',
  create: 'new',
  update: 'edit',
};

const routePaths = {
  view: [
    `/${routes.view}/:college/:campus/:property/:building/:level/:space`,
    `/${routes.view}/:college/:campus/:property/:building/:level`,
    `/${routes.view}/:college/:campus/:property/:building`,
    `/${routes.view}/:college/:campus/:property`,
    `/${routes.view}/:college/:campus`,
    `/${routes.view}/:college`,
    `/${routes.view}`,
  ],
  form: [
    `/:action(${routes.create})/:type/:parentId`,
    `/:action(${routes.update})/:type/:id`,
  ],
  reports: [
    '/reports/:collegeName/:jobNumber/:inspectionId/:sectionType',
    '/reports/:collegeName/:jobNumber/:inspectionId',
    '/reports/:collegeName/:jobNumber/',
    '/reports/:collegeName',
    '/reports',
  ],
};

// intercepts are attached to this instance
const axios = Axios.create({
  baseURL: api.baseUrl,
  timeout: 60000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

// This will not be modified further
const axiosDefault = Axios.create({
  baseURL: api.baseUrl,
  timeout: 60000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

// maintained by components/App @ App#getUser
const auth = {
  token: '',
};

const types = {
  //organization: 'organization',
  college: 'college',
  campus: 'campus',
  property: 'property',
  building: 'building',
  level: 'level',
  space: 'space',
  score: 'score',
};

const pluralMap = {
  //[types.organization]: 'organizations',
  [types.college]: 'colleges',
  [types.campus]: 'campuses',
  [types.property]: 'properties',
  [types.building]: 'buildings',
  [types.level]: 'levels',
  [types.space]: 'spaces',
  [types.score]: 'scores',
};

// ...and full circle
const singularMap = {
  //[types.organization]: 'organizations',
  [pluralMap[types.college]]: types.college,
  [pluralMap[types.campuses]]: types.campus,
  [pluralMap[types.property]]: types.property,
  [pluralMap[types.building]]: types.building,
  [pluralMap[types.level]]: types.level,
  [pluralMap[types.space]]: types.space,
  [pluralMap[types.score]]: types.score,
};

const scorePercentValueMap = {
  '0-10%': 1,
  '11-20%': 2,
  '21-45%': 3,
  '46-60%': 4,
  '61-100%': 5,
  0: 0,
};

const scoreValuePercentMap = {
  1: '0-10%',
  2: '11-20%',
  3: '21-45%',
  4: '46-60%',
  5: '61-100%',
  0: 0,
};

const contextProgression = Object.keys(types);

const network = {
  simulateOffline: false,
  // time in milliseconds between sync.statusCheck,
  // only called when offline
  statusPollTimeout: 5000,
};

const logger = new Logger({
  projectId: 1,
  projectKey:
    env === 'development' ? 'test' : '49628d1eaec36fbe0882f11b7d55f0ba',
  environment: env,
  host:
    env === 'development'
      ? 'http://localhost:8080'
      : 'https://errbit.beezwax.net',
});

// disable if test env
if (env === 'test') {
  logger.log = () => Promise.resolve();
}

/* Handled by ErrorBoundary
window.addEventListener('error', (err) => {
  logger.log(err);
});
*/

window.addEventListener('unhandledrejection', (err) => {
  logger.log(err);
});

let isIOS = false;
try {
  !!webkit;
  isIOS = true;
} catch (e) {}

export {
  api,
  auth,
  axios,
  axiosDefault,
  contextProgression,
  env,
  isIOS,
  logger,
  network,
  pluralMap,
  routes,
  routePaths,
  scorePercentValueMap,
  scoreValuePercentMap,
  singularMap,
  types,
};

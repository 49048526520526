import React, { Fragment } from 'react';
import ReportSection from '../../containers/ReportSection';

export default class EvaluationMethodology extends ReportSection {
  render() {
    const { content } = this.props;
    const currentList = () => {
      if (content.bullet_list) {
        return content.bullet_list.map((props, index) =>
          this.bullet(props, index)
        );
      } else {
        return [];
      }
    };
    return (
      <Fragment>
        <div id="bullet-list-info" className="report-content-padding">
          <p>Docs Received Text</p>
        </div>
        <div className="report-content-padding" id="bullet-list">
          {currentList()}
          {this.bullet(
            { resolved: '', value: '', confirmed: {} },
            currentList().length,
            true
          )}
        </div>
      </Fragment>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

export default function CustodialSpaceTypeItem({
  type,
  currentSpaceType,
  selectSpaceType,
}) {
  let className = 'custodial-space-type-item report-section';
  if (currentSpaceType === type) {
    className += ' selected';
  }
  return (
    <div className={className} onClick={selectSpaceType}>
      {type}
    </div>
  );
}

CustodialSpaceTypeItem.propTypes = {
  type: PropTypes.string.isRequired,
  currentSpaceType: PropTypes.string.isRequired,
  selectSpaceType: PropTypes.func.isRequired,
};

import Store from './store';
import FakeResponse from './fake';
import {
  api,
  axios,
  contextProgression,
  network,
  pluralMap,
  singularMap,
  types,
} from './config';
import { sortAscending } from './utils';
const isoStringMatcher = /^[0-9]{13,}$/;
const networkError = new Error('Network Error');

export function findParentIdField(url) {
  let resource = url.split('/').filter(Boolean)[2];
  resource = singularMap[resource];
  const resourceContextIndex = contextProgression.indexOf(resource);
  const context = contextProgression[resourceContextIndex - 1];
  if (!context) {
    throw new Error('Could not map URL to a context resource');
  }
  return `${context}_id`;
}

let sync;
/**
 * Singleton instance
 * handles syncing data from sessionStorage
 */
class Sync {
  constructor() {
    sync = this;
    sync.store = Store.instance('queue');
    sync.backlog = [];
    sync.running = false;
    sync.isOnline = true;
    sync.uniqueRequestId = 0;
    // this is used to signify switching from offline to online mode
    // sync.run will check if awaitingNetwork === true and will
    sync.awaitingNetwork = false;
  }

  static init() {
    // only allow one instance
    if (sync) {
      return sync;
    }
    return new Sync();
  }

  async isQueued() {
    const queue = await sync.queue;
    return queue.requests.length > 0;
  }

  get queue() {
    return sync.store.get();
  }

  /**
   * adds a hook method to sync
   */
  hook(type, func) {
    sync.hooks[type] = func;
  }

  /**
   * runs a specific hook method of <type>
   */
  runHook(type, args = []) {
    if (typeof sync.hooks[type] === 'function') {
      sync.hooks[type].apply(sync, args);
    }
  }

  checkNetwork() {
    return axios.get(api.heartbeat).then((res) => {
      // simulating offline state
      if (network.simulateOffline) {
        console.warn('Simulating offline...rejecting response');
        // ensure offline state
        sync.isOnline = false;
        return Promise.reject(networkError);
      }
      // still offline
      if (res.status !== 200) {
        sync.isOnline = false;
        return Promise.reject(networkError);
      }
      return Promise.resolve(true);
    });
  }

  /**
   * Continually check status until online again
   * if queue has items, then run it and update the
   * items in the store that it changes
   */
  awaitNetwork() {
    if (!sync.awaitingNetwork) {
      sync.runHook('online', [false]);
    }
    sync.awaitingNetwork = true;
    return sync
      .checkNetwork()
      .then((isOnline) => {
        // connected! sync will toggle isOnline status after running
        sync.awaitingNetwork = false;
        return sync.run(true);
      })
      .catch((err) => {
        setTimeout(sync.awaitNetwork, network.statusPollTimeout);
      });
  }

  /**
   * add single entry to queue
   * @params {object} req - req object with JSON data
   * @example req = {
   *  method: 'post',
   *  url: 'myurl',
   *  data: '{"name":"foo"}' //jsonified string
   * }
   */
  async add(req) {
    if (typeof req.data !== 'string') {
      throw new TypeError('Expected data to be of type string');
    }

    req.data = JSON.parse(req.data);
    req.data.authenticity_token = undefined;
    let id;
    if (['put', 'delete'].includes(req.method.toLowerCase())) {
      id = req.url.match(/[0-9]+$/);
      if (id.length) {
        id = id[0];
      }
    }
    // create temp id
    if (!req.data.id) {
      req.data.id = id || `${Date.now().toString()}${sync.uniqueRequestId++}`;
    }

    // use backlog if running or update the queue store
    if (sync.running) {
      sync.backlog.push(req);
    } else {
      const queue = await sync.queue;
      queue.requests.push(req);
      sync.store.set(queue);
    }

    let fakeRes = await FakeResponse.create(req);

    // start status check only if not running
    if (!sync.isOnline && !sync.awaitingNetwork) {
      sync.awaitNetwork();
    }
    // send back for fake response
    return Promise.resolve(fakeRes);
  }

  /**
   * Updates the image objects at score.attributes.image_urls
   * replacing the data capture to "path" with the ActiveStorage
   * file path from Rails
   * @params {object} data - the response data from the server
   * @params {object} item - current score object clone from the store
   */
  updateImageObjects(data, item) {
    // need to get list of all image_urls with offline === true
    // and sort their ids against any existing images
    const currentImageIds = [];
    Object.keys(item.attributes.image_urls).forEach((key) => {
      currentImageIds.push(Number(key));
    });
    // check if there are any new images (with timestamp ids)
    const newImageIds = currentImageIds.filter(
      (id) => id.toString().length >= 13
    );
    const newImageCount = newImageIds.length;
    if (newImageCount) {
      newImageIds.sort(sortAscending);
      // amount of images currently saved in our store
      const storeImageCount = currentImageIds.length;
      // the savedImageCount, from the response, will include 1 image from newImageIds
      const dataImageKeys = Object.keys(data.attributes.image_urls).sort(
        sortAscending
      );
      const dataImageCount = dataImageKeys.length;
      // if I data 2 of 3 images, and there were 2 already,
      // then I would have 4 image_urls in response and 1 left in store for 5 images total
      // so (4 data - 5 total) = -1 + (3 new Images) = 2 updated
      const addedImageCount = dataImageCount - storeImageCount + newImageCount;
      // make sure this request added an image
      if (addedImageCount > 0) {
        const imgTmpId = newImageIds.shift();
        const dataImageId = dataImageKeys.pop();
        const dataImage = data.attributes.image_urls[dataImageId];
        // replace with new objec
        delete item.attributes.image_urls[imgTmpId];
        item.attributes.image_urls[dataImageId] = dataImage;
      }
    }
  }

  /**
   * Goes through nearest ancestor in attributes and relationships
   * and makes sure their temporary ids are replaced with the
   * actual ids assigned from the server, this only happens if
   * the parent object was created
   */
  updateParentId(item, type, parentIdField, parentTmpId, idIndex) {
    let parentType = parentIdField.replace(/_id$/, '');
    item.relationships[parentType].data.id = idIndex[parentTmpId];
    // check if it has a <parentType>_id field or <parentType> object
    if (item.attributes[parentIdField]) {
      if (type === types.score) {
        item.attributes[parentIdField] = Number(idIndex[parentTmpId]);
      } else {
        item.attributes[parentIdField] = idIndex[parentTmpId];
      }
    } else if (item.attributes[parentType].id) {
      item.attributes[parentType].id = Number(idIndex[parentTmpId]);
    }
  }

  /**
   * Performs an atomic update to the store, when the item is found
   * it is cloned, you can change anything to the item and it will be
   * isolated to this function in case of an error
   */
  async updateStore(data, tmpId, parentTmpId, parentIdField, idIndex) {
    const storeIndex = pluralMap[data.type];
    const store = Store.instance(storeIndex);
    const items = await store.get();
    const item = items.find((item) => item.id === (tmpId || data.id));
    if (!item) {
      throw new Error('Item not found!');
    }
    // we mutate the store cache directly, nothing has access to state at this time
    item.id = item.attributes.id = data.id || idIndex[tmpId];

    if (parentTmpId) {
      this.updateParentId(item, data.type, parentIdField, parentTmpId, idIndex);
    }
    if (data.type === types.score) {
      this.updateImageObjects(data, item);
    }
    return store.upsert(item);
  }

  /**
   * Run the synchronization task, this is checked on initial page load
   * and ran when coming back online from an offline state
   * Called by sync.awaitNetwork()
   * @params {boolean} updateStore - set to true unless during initial page load
   */
  async run(updateStore = true) {
    if (sync.awaitingNetwork) {
      console.warn('Not Online, skipping');
      return;
    }
    sync.running = true;
    sync.runHook('running');
    let queue = await sync.queue;
    let { requests, idIndex } = queue;
    const catchErr = (err) => err;
    // there shouldn't be any requests coming through
    // when we are trying to sync the data
    let tmpId;
    // if our consumer breaks at any point
    let broken = false;
    // we specifically use the less desirable "requests.length" so that
    // our iterator's condition can be dynamic, which we need when
    // we add items to our backlog
    let syncedItems = 0,
      syncTotal = requests.length;
    for (let i = 0; i < requests.length; i++) {
      sync.runHook('progress', [syncedItems, syncTotal]);
      const req = requests[i];
      const parentField = findParentIdField(req.url);
      let parentTmpId = null;

      // check if we are creating a new item and it has a temp id
      if (isoStringMatcher.test(req.data.id)) {
        tmpId = req.data.id;
        // it will either find the tmpId or set undefined
        req.data.id = idIndex[tmpId];
      }
      // update the URL
      if (tmpId && idIndex[tmpId]) {
        req.url = req.url.replace(tmpId, idIndex[tmpId]);
      }
      // check for parent mapped ids
      if (isoStringMatcher.test(req.data[parentField])) {
        parentTmpId = req.data[parentField];
        req.data[parentField] = idIndex[parentTmpId];
      }

      // allows bypass in requestInterceptor so it won't automatically be rejected
      req.data.isSyncRequest = true;
      const res = await axios(req).catch(catchErr);

      if (res instanceof Error && res.message === 'Network Error') {
        broken = true;
        // network issues occurred, fallback to heartbeat
        break;
      }
      if (tmpId && res.data.data.id) {
        idIndex[tmpId] = res.data.data.id;
      }

      // empty the current backlog
      const backlog = sync.backlog.splice(0);
      syncTotal += backlog.length;
      // update the queue so that any new requests in our backlog are added on
      requests = requests.slice(i + 1).concat(backlog);
      // reduce count
      i -= 1;

      const queueUpdate = {
        // clear on last sync
        idIndex: requests.length > 0 ? idIndex : {},
        requests,
      };

      // update queue immediately
      await sync.store.set(queueUpdate);
      // TODO test different error types
      // check if we need to mock store data
      if (updateStore) {
        await sync
          .updateStore(res.data.data, tmpId, parentTmpId, parentField, idIndex)
          .catch((err) => console.error(err));
      }
      // update our iterator and extend the loop if necessary
      tmpId = null;
      syncedItems++;
    }

    sync.running = false;
    sync.runHook('finished');

    if (!broken) {
      sync.isOnline = true;
      sync.runHook('online', [true]);
    } else if (!sync.awaitingNetwork) {
      console.error(
        'Sync broke while running requests, are you offline again?'
      );
      // restart status
      sync.awaitNetwork();
    }
  }
}

// YAGNI...no need for event emitter
Sync.prototype.hooks = {
  online: null,
  running: null,
  progress: null,
  finished: null,
};

// All failed network requests will be pushed to our store
// and ingested by a consumer
Store.findOrSet('queue', {
  // once a temp id is replaced it is added to our index
  // so we can look it up in subsequent requests
  idIndex: {},
  requests: [],
});

Sync.init();

export default sync;
export { networkError };
